import * as React from 'react';
import { Box, Chip, useMediaQuery, Theme } from '@mui/material';
import {
    ListBase,
    Title,
    useGetResourceLabel,
} from 'react-admin';


const RecommendationList = () => {
    const getResourceLabel = useGetResourceLabel();
    return (
        <ListBase perPage={24} sort={{ field: 'reference', order: 'ASC' }}>
            <Title defaultTitle={getResourceLabel('recommendations', 2)} />
            <Box display="flex">
                
            </Box>
        </ListBase>
    );
};



export default RecommendationList;
