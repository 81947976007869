import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required,useGetOne,BooleanInput, ImageInput, ImageField, ReferenceInput, AutocompleteInput, useNotify, useRedirect, useRecordContext,useCreateSuggestionContext,
    useCreate,
    ReferenceField,
    useGetMany,
    useGetManyReference,useInput,useGetList,
    choices,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    AutocompleteInputProps
} from 'react-admin';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Contents from '../posts/Contents';
import { filter } from 'lodash';

const RecommendationCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [details,setDetails] =React.useState<any[]>([]);
    const onSuccess = (data: any) => {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
        redirect(`/posts/${data.post}`);
    };

    const OptionRenderer = () => {
    const record = useRecordContext();
    const { data, isLoading } = useGetOne('brands', {
        id: record.brand
    });
    const details = useGetMany('details', {
        ids: record.details
    }); 

  
    return (
        <span style={{display:"flex",width:"100%"}}>
            <div style={{height:100,margin:10,backgroundColor:"blue"}}> <img src={record.content_file} style={{height:"100%"}} /></div>
            <div style={{display:"flex",flexDirection:"column",margin:10}}>
                <p style={{margin:0,padding:0}}>{data?.name}</p>
                <p style={{margin:0,padding:0}}>{record.name}</p>
                <p style={{margin:0,padding:0}}>  {details.data?.map((item, index) => (
                                                    <span key={index}>{item.value + " "}</span>
                ))} </p>
            </div>
        </span>
    );
};

    const inputText = (choice: { name: any; description: any; }) => `${choice.name}`;
    const MatchSuggestion = (filter: string, choice: { name: string, description:any,details:any }) => {
    return (
        choice.name.toLowerCase().includes(filter.toLowerCase())|| (choice.description && choice.description.toLowerCase().includes(filter.toLowerCase()))
    );
};  


const CreateProduct = () => {
    const { filter, onCancel,onCreate} = useCreateSuggestionContext();
    const redirect = useRedirect();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        redirect(`/products/create`);
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    Click en aceptar para ser redirigido a Crear Productos
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Aceptar</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

    const GarmentInfo = () => {
        const record = useRecordContext();
        const { data, isLoading, error } = useGetManyReference(
            'garments',
            { 
                target: 'post',
                id: record.id,
            }
        );
        console.log(data);
        console.log(record);
        return(
        <div onClick={(e) => e.preventDefault()}>
                Garments 
        </div>)
    }

    



interface Props {
  source: string;
}

const ProductInput = ({ source }: Props) => {
  const { id, field, fieldState } = useInput({ source });
  console.log(id, field, fieldState);

  const [filter, setFilter] = React.useState([]);
  const [brandId, setBrandId] = React.useState([]);
  const [DetailId, setDetailId] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState(-1);
  

  


  const handleChange: AutocompleteInputProps['onChange'] = (
    value,
    record
    ) => {
        setDetailId(value);
    };

    const handleChangeBrand: AutocompleteInputProps['onChange'] = (
        value,
        record
        ) => {
            setBrandId(value);
        };
    
    const handleChangeName: AutocompleteInputProps['onChange'] = (
        value,
        record
        ) => {
            setFilter(value);
        };

    const handleClick = (e: any ,id : number) =>  {
        e.stopPropagation();
        e.preventDefault();
        setSelectedItem(id);
        field.onChange(id);
    }
        

  const { data, total, isLoading, error, refetch } = useGetList(
    "products",
    {
      pagination: { page: 1, perPage: 50 },
      sort: { field: 'created_at', order: 'DESC' },
      filter: { id: filter, details: DetailId, brand: brandId } // Asumiendo que estos son los campos por los que deseas filtrar
    }
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{display:"flex",flexDirection:"row",width:"100%",gap:"20px"}}>
            <ReferenceArrayInput source="detailId" reference="details" label="Detalles" >
                <AutocompleteArrayInput optionText="value" onChange={handleChange} />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="brandId" reference="brands" label="Marca" >
                <AutocompleteArrayInput optionText="name" onChange={handleChangeBrand} />
            </ReferenceArrayInput>
            {/* <ReferenceArrayInput source="productId" reference="products" label="Nombre de Productos" >
                <AutocompleteArrayInput optionText="name" onChange={handleChangeName} />
            </ReferenceArrayInput> */}
        </div>
        <div style={{display:"flex",flexDirection:"row",flexWrap:"wrap", gap:"5px", padding:"10px"}}>
        {isLoading ? (
          <div>Cargando...</div>
        ) : (
          data?.map((record) => (
            <button onClick={(e) => handleClick(e,record.id)} style={{padding:"5px", border:record.id === selectedItem ?  "3px solid blue" : "3px solid white" , background:"none", borderRadius:"12px" }}>
                <img  src={record.content_file} style={{width:"150px",height:"150px"}}   />
            </button>
          ))
        )}
        {data?.length === 0 && <div> No se encontraron productos con estos filtros</div>}
        {error && <div>Error cargando productos: {error.message}</div>}
      </div>
    </div>
  );
};


    return (
        <Create mutationOptions={{onSuccess}}>
            <TabbedForm defaultValues={{ store: 1 }}>
                <FormTab
                    label="resources.products.tabs.image"
                    sx={{maxWidth:window.innerWidth}}
                >
                    <ReferenceField source="post" reference="posts" sx={{  }}>
                        <Contents />
                    </ReferenceField>
                    <ReferenceField source="post" reference="posts" sx={{  }}>
                        <GarmentInfo />
                    </ReferenceField>
                    <div style={{visibility:"hidden"}}>
                    <ReferenceInput source="post" reference="posts" sx={{  }}>
                        <AutocompleteInput validate={required()} sx={{  }}  fullWidth={true}/>
                    </ReferenceInput>
                    </div>

                    Buscar Productos Existentes
                    <ProductInput source="product"/>
                    <MUIButton onClick={(event)=>  {
                            event.preventDefault();
                            redirect(`/products/create`);
                        }}
                    > Crear un producto nuevo </MUIButton>
                    <BooleanInput label="Es exacta ? " source="is_exact" />

                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default RecommendationCreate;