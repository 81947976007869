import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required, ImageInput, ImageField, ReferenceInput, AutocompleteInput, useNotify, useRedirect, BooleanInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';

//import { ProductEditDetails } from './ProductEditDetails';

//const filterToQuery = (searchText: string) => ({ name_ilike: `%${searchText}`});

const BrandCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    
    const onSuccess = (data: any) => {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
        redirect(`/posts/${data.post}`);
    };


    return (
        <Create mutationOptions={{onSuccess}}>
            <TabbedForm defaultValues={{ store: 1 }}>
                <FormTab
                    label="resources.products.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                    <TextInput source="name" validate={required()} />
                    <TextInput source="link" validate={required()} />
                    <BooleanInput source ="is_partner" validate={required()} />    
                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default BrandCreate;