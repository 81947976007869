import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    useGetManyReference,
    useTranslate,
    Link,
    FieldProps,
    BooleanInput,
    SelectInput,
    ReferenceInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    SelectArrayInput,
    useCreateSuggestionContext,
    useCreate, 
} from 'react-admin';
import { User } from '../types';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';



const UserTitle = () => {
    const record = useRecordContext<User>();
    //console.log(record)
    return record ? <span>{record.first_name}  {record.last_name}</span> : null;
};


const matchSuggestionName = (filter: string, choice: {name: string }) => {
    return (
        choice?.name?.toLowerCase().includes(filter.toLowerCase())
    );
    }   


    const CreateSegments = () => {
        const { filter, onCancel, onCreate } = useCreateSuggestionContext();
        const [value, setValue] = React.useState(filter || '');
        const [create] = useCreate();
    
        const handleSubmit = (event: any) => {
            event.preventDefault();
            create(
                'segments',
                {
                    data: {
                        name: value,
                    },                
                },
                {
                    onSuccess: (data) => {
                        setValue('');
                        onCreate(data);
                    },
                }
            );
        };
    
        return (
            <Dialog open onClose={onCancel}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <MUITextField
                            label="Nuevo  Segmento "
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <MUIButton type="submit">Save</MUIButton>
                        <MUIButton onClick={onCancel}>Cancel</MUIButton>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };


    const CreateRoles = () => {
        const { filter, onCancel, onCreate } = useCreateSuggestionContext();
        const [value, setValue] = React.useState(filter || '');
        const [create] = useCreate();
    
        const handleSubmit = (event: any) => {
            event.preventDefault();
            create(
                'roles',
                {
                    data: {
                        name: value,
                    },                
                },
                {
                    onSuccess: (data) => {
                        setValue('');
                        onCreate(data);
                    },
                }
            );
        };
        return (
            <Dialog open onClose={onCancel}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <MUITextField
                            label="Nuevo  Rol "
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <MUIButton type="submit">Save</MUIButton>
                        <MUIButton onClick={onCancel}>Cancel</MUIButton>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };


const UserEdit = () => (
    <Edit title={<UserTitle />}>
        <TabbedForm>
            <FormTab
                    label="resources.products.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                   
                    <TextInput source="username" validate={required()} />
                    <TextInput source="first_name" validate={required()} />
                    <TextInput source="last_name" validate={required()} />
                    <TextInput source="email" validate={required()} />
                    <TextInput source="phone" validate={required()} />
                    <TextInput source="ig_user" />
                    <TextInput source="tiktok_user"  />
                    <BooleanInput source="is_staff"/>
                    <BooleanInput source="is_active"/>
                    <TextInput source="_oldUser" />
                    <SelectInput
                        source="country" choices={[{id:"CL",name:"Chile"}]} />
                    <ReferenceArrayInput source="segments" reference="segments"  sx={{ }}>
                        <AutocompleteArrayInput  sx={{  }}  fullWidth={true}  optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateSegments />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear segmentos ',}}/>
                    </ReferenceArrayInput>
                    <ReferenceArrayInput source="roles" reference="roles" sx={{ }}>
                        <AutocompleteArrayInput  sx={{  }}  fullWidth={true}  optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateRoles />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear roles ',}} />
                    </ReferenceArrayInput>
                    <ImageInput source="profile_picture">
                        <ImageField source="src" />
                    </ImageInput>
                </FormTab>
        </TabbedForm>
    </Edit>
);

const req = [required()];


export default UserEdit;