import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FormTab,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    useGetManyReference,
    useTranslate,
    ImageField,
    UrlField,
    DateInput,
    useGetOne,
    ReferenceField,
    DeleteButton,
    CreateButton,
    Button,
    Link,
    SelectArrayInput,
    BooleanInput,
    NumberInput,
    FileInput,
    ReferenceInput,
    AutocompleteInput,
    useInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useCreateSuggestionContext,
    useCreate,
    useRedirect,
    useNotify
    

} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import Content from './Contents';
import { Post, Recommendation } from '../types';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Autocomplete from "react-google-autocomplete";
import { TagsInput } from "react-tag-input-component";




   

const PlaceInput = (props :any) => {
    const { id, field, fieldState } = useInput(props);
    //console.log(id, field, fieldState)

    return (
        <Autocomplete 
                    style={{
                        width: "100%",
                        height: 50,
                        padding: 5,
                        backgroundColor: "#e0e0e3",
                        border:"none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                        borderRadius:"10px 10px 0 0 ",
                        marginBottom:10,
                      }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                    options={{ types: ["establishment"], fields: ["place_id","url","name"] }}
                    onPlaceSelected={(place) =>  {
                        if(place)
                            field.onChange(place); 
                        }}
        />
    );
    };

    const optionRenderer = (choice: { first_name: any; last_name: any; }) => `${choice.first_name} ${choice.last_name}`;
    const matchSuggestion = (filter: string, choice: { username: string, first_name: string, last_name :string }) => {
        return (
            choice?.username?.toLowerCase().includes(filter.toLowerCase())|| choice?.first_name?.toLowerCase().includes(filter.toLowerCase())|| choice?.last_name?.toLowerCase().includes(filter.toLowerCase())
        );
        }

    const matchSuggestionName = (filter: string, choice: {name: string }) => {
            return (
                choice?.name?.toLowerCase().includes(filter.toLowerCase())
            );
            }   
            
            
const CreateNewUser = () => {
    const { filter, onCancel,onCreate} = useCreateSuggestionContext();
    const redirect = useRedirect();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        redirect(`/users/create`);
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    Click en guardar para ser redigirido a crear usuario
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const CreateTag = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'categories',
            {
                data: {
                    name: value,
                },                
            },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label="Nueva Categoria"
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const PostEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data : any) => {
        notify(`Changes saved`);
        //console.log(data)
        redirect(`/posts/`);
    };

return (
    <Edit >
        <TabbedForm>
            <FormTab
            label="Exportar Look Viejo ">
                <Content />
                    <ReferenceArrayInput source="albums" reference="albums">
                        <AutocompleteArrayInput  validate={required()} label="Albums" fullWidth={true}  optionText='name' matchSuggestion={matchSuggestionName} /> 
                    </ReferenceArrayInput>

                    <ReferenceInput source="user" reference="users" sx={{ }}>
                    <AutocompleteInput validate={required()} sx={{  }}  fullWidth={true}  optionText={optionRenderer} matchSuggestion={matchSuggestion} create={<CreateNewUser />} />
                    </ReferenceInput>

                    <BooleanInput label="Es hot? " source="is_hot" defaultValue={true}  />
                    <BooleanInput label="Es global? " source="is_global" defaultValue={true} />
                    <BooleanInput label="Es visible? " source="is_visible"  defaultValue={true} />
                    <RichTextInput source="description" />
                    <ReferenceArrayInput source="categories" reference="categories">
                        <AutocompleteArrayInput  validate={required()} label="categories" fullWidth={true}   optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateTag />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear categorias ',}} /> 
                    </ReferenceArrayInput>

                    <p> Solo completar si es categoria travel </p>
                   
                    <NumberInput source="grade"  min={0} max={5} step={0.5} defaultValue={null}/>
                    <PlaceInput source="place" label="Place"   />
                    
            </FormTab>
        </TabbedForm>
    </Edit>
);
                        }

const req = [required()];



export default PostEdit;