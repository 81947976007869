import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BrandList from './BrandList'
import BrandCreate from './BrandCreate'
import BrandEdit from './BrandEdits';

export default {
    list: BrandList,
    create: BrandCreate,
    edit: BrandEdit,
    icon: DynamicFeedIcon,
}