import * as React from 'react';
import { Box, Chip, useMediaQuery, useTheme } from '@mui/material';
import {
    List,
    Title,
    useGetResourceLabel,
    Datagrid,
    DateField,
    TextField,
    UrlField,
    TopToolbar,
    SortButton,
    ExportButton,
    FilterButton,
    CreateButton,
    
    
} from 'react-admin';


const BrandList = () => {
    const theme = useTheme();
    const isXsmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const getResourceLabel = useGetResourceLabel();
    return (
        <List sort={{ field: 'reference', order: 'ASC' }}
            perPage={25}
            actions={<ListActions isSmall={isSmall} />}
            >

                      <Datagrid
                            optimized
                            rowClick="edit"
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                        <TextField source="title"/>
                        <DateField source="created_at" />
                        <UrlField source="link" />
                        </Datagrid>
       
        </List>
    );
};



const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {/* {isSmall && <FilterButton />} */}
        <SortButton fields={['created_at', ]} />
        <ExportButton />
        <CreateButton />
    </TopToolbar>
);


export default BrandList;
