import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required, ImageInput, ImageField, ReferenceInput, AutocompleteInput,useCreateSuggestionContext,
    useCreate, useNotify, AutocompleteArrayInput, ReferenceArrayInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { useNavigate } from 'react-router';


//import { ProductEditDetails } from './ProductEditDetails';
import Apparel from './Apparel';

//const filterToQuery = (searchText: string) => ({ name_ilike: `%${searchText}`});

const matchSuggestionName = (filter: string, choice: {name: string }) => {
    return (
        choice?.name?.toLowerCase().includes(filter.toLowerCase())
    );
    }   






const CreateBrand = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [link, setLink] = React.useState('');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'brands',
            {
                data: {
                    name: value,
                    link : link,
                },                
            },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label="Nueva Marca "
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                    <br />
                     <MUITextField
                        label="Link Marca "
                        value={link}
                        onChange={event => setLink(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const ProductCreate = () => {
    const navigate = useNavigate();
    const notify = useNotify();

    const onSuccess = () => {
        notify(`Changes saved`);
        navigate(-1);
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <TabbedForm defaultValues={{ store: 1 }}>
            <FormTab
                label="resources.products.tabs.image"
                sx={{ maxWidth: '40em' }}
            >
                <Apparel />
                <ImageInput source="content_file" validate={required()}>
                    <ImageField source="src" />
                </ImageInput>
                <TextInput label="Link" source="_link" validate={required()} />
                <ReferenceInput source="brand" reference="brands">
                    <AutocompleteInput validate={required()} optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateBrand/>} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear una marca ',}} />
                </ReferenceInput>
                <TextInput source="name" fullWidth validate={required()} />
                <RichTextInput source="description" />
                <ReferenceArrayInput source="details" reference="details">
                        <AutocompleteArrayInput  validate={required()} label="Detalles" fullWidth={true}  optionText='value'  /> 
                </ReferenceArrayInput>
            </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default ProductCreate;