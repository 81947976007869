import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PostList from './PostList';
import PostCreate from './PostCreate';
import PostEdit from './PostEdit';

export default {
    list: PostList,
    create: PostCreate,
    edit: PostEdit,
    icon: DynamicFeedIcon,
}