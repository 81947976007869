import * as React from 'react';
import { Stack, Chip } from '@mui/material';
import { FieldProps, useTranslate, useRecordContext, useGetManyReference } from 'react-admin';
import { User, Role } from '../types';


const RolesField = (props: FieldProps) => {
    //const translate = useTranslate();
    const record = useRecordContext<User>();

    if (!record || !record.roles) {
        return null;
    }
    return (
        <Stack direction="row" gap={1} flexWrap="wrap">
            {record.roles.map((role: Role) => (
                <Chip
                    size="small"
                    key={role.id}
                    label={role.name}
                />
            ))}
        </Stack>
    );
};

export default RolesField;