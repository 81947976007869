import jwt_decode from 'jwt-decode';
import { AuthProvider, fetchUtils, Identifier } from 'ra-core';

const API_URL = process.env.REACT_APP_API_URL;
//const API_URL = "https://canneberge.cranberrychic.com"


interface JWTToken {
    token_type: string;
    exp: number;
    iat: number;
    jti: string;
    user_id: Identifier;
    is_staff: boolean;
    full_name: string;
    profile_picture: string;
}

const authProvider = (options: object = {}): AuthProvider => {
    const opts = {
        obtainAuthTokenUrl: '/api/token/',
        ...options
    };
    return {
        login: async ({ email, password }) => {
            const request = new Request(API_URL + '/api/admin/token/', {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            const response = await fetch(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            const { refresh, access } = await response.json();
            localStorage.setItem("refreshToken", refresh);
            localStorage.setItem("accessToken", access);
            //console.log("Login success.")
        },

        logout: params => {
            //console.log("logout");
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            return Promise.resolve();
        },

        checkAuth: (params) => {
            //console.log("Checking authorization.");
            const accessToken = localStorage.getItem('accessToken');
            const refreshToken = localStorage.getItem('refreshToken');

            if (accessToken && refreshToken) {
                //console.log(accessToken);
                const { exp, is_staff } = jwt_decode(accessToken) as JWTToken;
                if (!is_staff) {
                    //console.log("User is not an Admin");
                    return Promise.reject({ message: "ra.auth.auth_check_error" });
                }

                if (exp > (new Date().getTime() / 1000) - 10) {
                    return Promise.resolve();
                } else {
                    //console.log("New access token from refresh.")
                    const { exp: refreshExp } = jwt_decode(refreshToken) as JWTToken;
                    if (refreshExp < (new Date().getTime() / 1000) - 10) {
                        return Promise.reject({ message: "Need to login again." });
                    };
                    const request = new Request(API_URL + '/api/token/refresh/', {
                        method: 'POST',
                        body: JSON.stringify({ "refresh": refreshToken }),
                        headers: new Headers({ 'Content-Type': 'application/json' }),
                    });

                    const response = fetch(request)
                        .then(response => {
                            if (response.status !== 200) {
                                throw new Error(response.statusText);
                            }
                            return response.json();
                        })
                        .then(({ access }) => {
                            //console.log("Setting new token:");
                            localStorage.setItem('accessToken', access);
                            return Promise.resolve();
                        });

                    return response;
                }
            }
            return Promise.reject({ message: "login.required" });
        },

        checkError: error => {
            //console.log("checkError")
            if (error.status === 401 || error.status === 403) {
                return Promise.reject();
            }
            return Promise.resolve();
        },

        getIdentity: () => {
            try {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                    const { user_id: id, full_name: fullName, profile_picture: avatar } = jwt_decode(accessToken) as JWTToken;
                    return Promise.resolve({ id, fullName, avatar });
                }
                return Promise.reject({message: 'login.required'})
            } catch (error) {
                return Promise.reject(error);
            }
        },

        getPermissions: params => Promise.resolve(),
    }
}

export function createOptionsFromJWTToken() {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        return {};
    }
    return {
        user: {
            authenticated: true,
            token: 'Bearer ' + token,
        },
    };
}

export function fetchJsonWithAuthJWTToken(url: string, options: object) {
    return fetchUtils.fetchJson(
        url,
        Object.assign(createOptionsFromJWTToken(), options)
    );
}


export default authProvider;