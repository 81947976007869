import * as React from 'react';
import { Card, CardMedia } from '@mui/material';
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { Post, PostContent } from '../types';
import CarouselContent from './Contents/CarouselContent';




const Content = (record: PostContent) => {
    return (
        <Card sx={{ display: 'inline-block' }}>
            <CardMedia
                component='img'
                image={record.content_file}
                alt=""
                //sx={{ maxWidth: '42em', maxHeight: '15em' }}
            />
        </Card>
    );
};

const Contents = () => {
    const record = useRecordContext<Post>();
    if (!record) return null;
    const contents = record.contents;
    return(
             <CarouselContent id={record?.id} data={record?.contents} size={"300"} ratioSquare={record?.contents[0].content_ratio==="1:1" }/>  
    )
};



export default Contents;