import * as React from 'react';
import { Link, FieldProps, useRecordContext, TextField } from 'react-admin';

//import FullNameField from './FullNameField';
import { Brand } from '../types';

const BrandLinkField = (props: FieldProps<Brand>) => {
    const record = useRecordContext<Brand>();
    if (!record) {
        return null;
    }
    //console.log(record);
    return (
        <Link to={`/brands/${record.id}`}>
            <TextField source="name" />
        </Link>
    );
};

export default BrandLinkField;