import * as React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { Box, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import { useCreatePath, NumberField, useListContext, ReferenceManyField, ImageField, Datagrid, TextField, ArrayField, SingleFieldList, useGetOne } from 'react-admin';
import { Link } from 'react-router-dom';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

const GridList = () => {
    const { isLoading } = useListContext();
    return isLoading ? <LoadingGridList /> : <LoadedGridList />;
};

const useColsForWidth = () => {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));
    const lg = useMediaQuery(theme.breakpoints.up('lg'));
    const xl = useMediaQuery(theme.breakpoints.up('xl'));
    // there are all dividers of 24, to have full rows on each page
    if (xl) return 6;
    if (lg) return 4;
    if (md) return 3;
    if (sm) return 2;
    return 1;
};

const times = (nbChildren: number, fn: (key: number) => any) =>
    Array.from({ length: nbChildren }, (_, key) => fn(key));

const LoadingGridList = () => {
    const { perPage } = useListContext();
    const cols = useColsForWidth();
    return (
        <ImageList cols={cols} sx={{ m: 0 }}>
            {times(perPage, key => (
                <ImageListItem key={key}>
                    <Box bgcolor="grey.300" height="100%" />
                </ImageListItem>
            ))}
        </ImageList>
    );
};



const LoadedGridList = () => {
    const { data } = useListContext();
    const cols = useColsForWidth();
    const createPath = useCreatePath();

    
    

    if (!data) return null;
    //console.log(data)
    return (
        <ImageList cols={cols-1} sx={{ m: 0 }} variant="quilted">
            {data.map(record => {
                //console.log(record)

                if (record.is_migrated){
                    return  (
                        <ImageListItem
                        component={Link}
                        key={record.id}
                        to={createPath({
                            resource: 'posts',
                            id: record.post,
                            type: 'edit',
                        })}
                    >   
                
                        {
                            
    
                            <img
                            src={record?.content_file}
                            style={{filter: "grayscale(100%)"}}
                            />
    
                        }
                       
                        
                        <ImageListItemBar
                            subtitle={record.old_fullname} 
                            title={ <span style={{display:"flex",justifyContent:"flex-start",textAlign:"center"}}><CloudDoneIcon style={{fontSize:30, color:"green"}} /> Post Migrado </span>}
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
                            }}
                        />
                    </ImageListItem>
                )
                }
                else{
                    return  (
                        <ImageListItem
                        component={Link}
                        key={record.id}
                        to={createPath({
                            resource: 'oldlooks',
                            id: record.id,
                            type: 'edit',
                        })}
                    >   
                
                        {
                            
    
                            <img
                            src={record?.content_file}
                            />
    
                        }
                       
                        
                        <ImageListItemBar
                            subtitle={record.old_fullname} 
                            title={ <span> </span>}
                            sx={{
                                background:
                                    'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
                            }}
                        />
                    </ImageListItem>
                )
                }

                
                
            })}
        </ImageList>
    );
};

export default GridList;
