import * as React from 'react';
import { Create, FormTab, TabbedForm, TextInput, required, SelectInput, ReferenceInput, AutocompleteInput, useNotify, useRedirect, DateTimeInput, NumberInput, FileInput, FileField, NumberField, useInput, useCreateSuggestionContext, useCreate } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Dialog, DialogContent, TextField as MUITextField, Button as MUIButton, DialogActions,} from '@mui/material';
import { set } from 'lodash';


//import { ProductEditDetails } from './ProductEditDetails';

//const filterToQuery = (searchText: string) => ({ name_ilike: `%${searchText}`});

const BenefitCreate = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const types  = [{id:"regalo", name :"Regalo"} ,{id: "descuento", name : "Descuento"} ,{id: "viaje", name : "Viaje"} ,{id: "restaurantes", name : "Restaurantes"} ,{id: "clases", name :  "Clases"}]  ;

    const CreateBrand = () => {
        const { filter, onCancel, onCreate } = useCreateSuggestionContext();
        const [value, setValue] = React.useState(filter || '');
        const [link, setLink] = React.useState('');
        const [create] = useCreate();

        const handleSubmit = (event: any) => {
            event.preventDefault();
            create(
                'brands',
                {
                    data: {
                        name: value,
                        link: link,
                    },
                },
                {
                    onSuccess: (data) => {
                        setValue('');
                        onCreate(data);
                    },
                }
            );
        };

        return (
            <Dialog open onClose={onCancel}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <MUITextField
                            label="Nueva Marca "
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            autoFocus
                        />
                        <br />
                        <MUITextField
                            label="Link Marca "
                            value={link}
                            onChange={event => setLink(event.target.value)}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <MUIButton type="submit">Save</MUIButton>
                        <MUIButton onClick={onCancel}>Cancel</MUIButton>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };

    const matchSuggestionName = (filter: string, choice: { name: string }) => {
        return (
            choice?.name?.toLowerCase().includes(filter.toLowerCase())
        );
    }


    const onSuccess = (data: any) => {
        notify('ra.notification.created', { messageArgs: { smart_count: 1 } });
        redirect(`/benefits/${data.id}`);
    };


    const formatDate = (inputDate: string | number | Date) => {
        const date = new Date(inputDate);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        const timezoneOffset = -date.getTimezoneOffset();
        const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
        const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
        const offsetSign = timezoneOffset >= 0 ? '+' : '-';

        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;

        return formattedDate;
    };

    const DurationInput = (props: any) => {
        const { id, field, fieldState } = useInput(props);
        const initial_date = field.value.split(":");
        const [days, setDays] = React.useState(initial_date[0] === '' ? "" : initial_date[0]);
        const [hours, setHours] = React.useState(initial_date[1] ? initial_date[1] : "");
        const [minutes, setMinutes] = React.useState(initial_date[2] ? initial_date[2] : "");
    
        const handleChangeDays = (e: React.ChangeEvent<HTMLInputElement>) => {
            let formattedDays = parseInt(e.target.value.replace(/\D/g, ''));
            console.log(formattedDays)
            let stringDays = "";
            if (formattedDays < 10 && formattedDays > 0) {
                stringDays = '0' + formattedDays;
            } else if (formattedDays <= 0) {
                stringDays = '00';
            }
            else{
                stringDays = stringDays + formattedDays
            }
            setDays(stringDays);
        };
    
        const handleChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
            let formattedHours = parseInt(e.target.value.replace(/\D/g, ''));
            console.log(formattedHours)
            let stringHours = "";
            if (formattedHours > 23) {
                stringHours = '23';
            } else if (formattedHours < 10 && formattedHours > 0) {
                stringHours = '0' + formattedHours;
            } else if (formattedHours <= 0) {
                stringHours = '00';
            }
            else{
                stringHours = stringHours + formattedHours
            }
            setHours(stringHours);
        };
    
        const handleChangeMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
            let formattedMinutes = parseInt(e.target.value.replace(/\D/g, ''));
            console.log(formattedMinutes)
            let stringMinutes = "";
            if (formattedMinutes > 59) {
                stringMinutes = '59';
            } else if (formattedMinutes < 10 && formattedMinutes > 0) {
                stringMinutes = '0' + formattedMinutes;
            } else if (formattedMinutes <= 0) {
                stringMinutes = '00';
            }
            else{
                stringMinutes = stringMinutes + formattedMinutes
            }
            setMinutes(stringMinutes);
        };
    
        React.useEffect(() => {
            field.onChange(`${days === "" ? "00" : days}:${hours === "" ? "00" : hours}:${minutes === "" ? "00" : minutes}`);
        }, [days, hours, minutes]);
    
        return (
            <div className='DurationTimeBox'>
                <MUITextField
                    name="days"
                    value={days}
                    onChange={handleChangeDays}
                    type="number"
                    placeholder="Dias"
                />
                <MUITextField
                    name="hours"
                    value={hours}
                    onChange={handleChangeHours}
                    type="number"
                    placeholder="Horas"
                />
    
                <MUITextField
                    name="minutes"
                    value={minutes}
                    onChange={handleChangeMinutes}
                    type="number"
                    placeholder="Minutos"
                />
            </div>
        );
    };
    


    const validateLength = (value : any) => {
        if (value.length > 1000) {
            return "Texto debe ser de 1000 caracteres  o menos .(Largo Actual :" + value.length + ")";
        }
        return undefined;
    };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <TabbedForm defaultValues={{ store: 1 }}>

                <FormTab
                    label="resources.products.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                    
                    Selecciona el Tipo de Benefico a crear.
                    <SelectInput
                        source="benefit_type"
                        choices={types}
                        optionText="name"
                        optionValue="id"
                        validate={required()} 
                    />
                    Selecciona  a que marca corresponde este beneficio.
                    <ReferenceInput source="brand" reference="brands">
                        <AutocompleteInput validate={required()}  fullWidth={true}optionText='name' matchSuggestion={matchSuggestionName} create={<CreateBrand />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear una marca ',
                        }} />
                    </ReferenceInput>
                    Utiliza un titulo descriptivo y llamativo.
                    <TextInput source="title" validate={required()}  fullWidth={true}/>
                    Describe el beneficio, las reglas y las tareas que se deben cumplir para poder completarlo.
                    <RichTextInput source="description" validate={[required(),validateLength]} />
                    Selecciona cuantos invitados pueden utilizar el beneficio.
                    <NumberInput source="limit" label="Máximo número de invitados" validate={required()}/>
                    Delimita un tiempo para que las influencers puedan aceptar un nuevo beneficio, antes de que corra la lista de espera.( Dias : Horas : Minutos)
                    <DurationInput source="time_to_accept"  validate={required()}/>
                    Delimita un tiempo para que las influencers puedan enviar sus comprobantes, antes de definir el beneficio como incompleto.( Dias : Horas : Minutos)
                    <DurationInput source="time_to_succeed" validate={required()} />
                    Selecciona el ultimo dia donde el beneficio puede ser  completado.
                    <DateTimeInput source="expiration" parse={v => formatDate(v)} label="Último día válido para el beneficio" validate={required()} />

                    Si es que existe, adjunta un archivo para que las influencers que acepten tenga mas informacion del beneficio.
                    <FileInput source="attachment">
                        <FileField source="src" title="title" />
                    </FileInput>

                </FormTab>
            </TabbedForm>
        </Create>
    );
};

export default BenefitCreate;