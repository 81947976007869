import * as React from 'react';
import {
    CreateButton,
    Datagrid,
    List,
    ReferenceInput,
    SelectInput,
    TextInput,
    TopToolbar,
    SortButton,
    ExportButton,
    FilterButton,
    Button,
    FunctionField,
    ReferenceArrayField,
    TextField,
    SingleFieldList,
    ChipField,
    DateField,
} from 'react-admin';
import { useMediaQuery, Theme, useTheme } from '@mui/material';

import RolesField from './RolesField';
import UserLinkField from './UserLinkField';
import MobileGrid from './MobileGrid';
import UserListAside from './UserListAside';
import { MailOutline } from '@mui/icons-material';
import { enviarSolicitud } from '../dataProvider';

const userFilters = [
    <TextInput source="country" />,
    <ReferenceInput
        source="roles"
        reference="roles"
        sort={{ field: 'id', order: 'ASC' }}
    >
        <SelectInput source="name" />
    </ReferenceInput>,
];

const UserList = () => {
    const theme = useTheme();
    const isXsmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));



    const RowButton =  (selectedUser: any) => {
    //console.log(selectedUser);
    
    const handleClick = (e : any, id : number) => {
        e.stopPropagation();
        enviarSolicitud(id)
    };

    if (!selectedUser.selectedUser.is_active){
        return(
            <Button 
                    children={<div style={{display:"flex",flexDirection:"row",margin:0,padding:0,}}><MailOutline /> 
                    <p style={{fontSize:10,margin:0,padding:0}}>Enviar activación cuenta</p></div>}
                    onClick={(e) => handleClick(e,selectedUser.selectedUser.id)}
                    />
        )
    }
    else {
        return (
            <div/>
        )
    }
}



    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'last_seen', order: 'DESC' }}
            perPage={25}
            aside={<UserListAside />}
            actions={<ListActions isSmall={isSmall} />}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid
                    optimized
                    // rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <UserLinkField  />
                    <ReferenceArrayField label="Roles" reference="roles" source="roles">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField label="Segmentos" reference="segments" source="segments">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                    </ReferenceArrayField>
                    <TextField source="country" />
                    <TextField source="username" />
                    <TextField source="email" />
                    <TextField source="phone" />
                    <DateField source="date_joined" />
                   
                    <FunctionField render={(record: any) => <RowButton selectedUser={record}/> } /> 

                </Datagrid>
            )}
        </List>
    );
};



const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        <SortButton fields={['created_at', ]} />
        <ExportButton />
        <CreateButton />
    </TopToolbar>
);

export default UserList;
