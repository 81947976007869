import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    useGetManyReference,
    useTranslate,
    Link,
    FieldProps,
    BooleanInput,
    SelectInput,
    ReferenceInput,
    AutocompleteArrayInput,
} from 'react-admin';
import { Brand } from '../types';


const BrandTitle = () => {
    const record = useRecordContext<Brand>();
    return record ? <span>{record.name} </span> : null;
};

const BrandEdit = () => (
    <Edit title={<BrandTitle />}>
        <TabbedForm>
            <FormTab
                    label="resources.products.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                    <TextInput source="name" validate={required()} />
                    <TextInput source="link" validate={required()} />
                    <BooleanInput source ="is_partner" validate={required()} />    
                    
                </FormTab>
        </TabbedForm>
    </Edit>
);

const req = [required()];


export default BrandEdit;