import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    FormTab,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    useRecordContext,
    ImageField,
    useGetOne,
    ReferenceField,
    DeleteButton,
    Button,
    Link,
    BooleanInput,
    NumberInput,
    ReferenceInput,
    AutocompleteInput,
    useInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useCreateSuggestionContext,
    useCreate,
    useNotify,
    useRedirect,
    useUpdate,
    ReferenceArrayField,
    ChipField,
    SingleFieldList
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import Contents from './Contents';
import BrandLinkField from './BrandLinkField';
import { Post, Recommendation } from '../types';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow,Switch } from '@mui/material';
import Autocomplete from "react-google-autocomplete";
import AddIcon from '@mui/icons-material/Add';
import PinIcon from '@mui/icons-material/Place';
import { ConstructionOutlined } from '@mui/icons-material';
import CloseIcon from './Contents/SVG/CloseIcon';


const PostTitle = () => {
    const record = useRecordContext<Post>();
    const username = `${record.user.first_name} ${record.user.last_name}`;
    return record ? <span>Post id:{record.id} by {username}"</span> : null;
};

const HitRecommendation = () => {
    const record = useRecordContext<Recommendation>();
    const { data, isLoading, error, refetch } = useGetOne(
        'recommendations',
        { id: record.id, meta: { action: 'hits' } }
    )
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 7);
    const defaultEndDate = new Date();

    return (
        <Card>
            {/*
            <DateInput source="start_date" defaultValue={defaultStartDate} />
            <DateInput source="end_date" defaultValue={defaultEndDate} />
            */}

        </Card>
    );
};

const OptionRenderer = () => {
    const record = useRecordContext();
    return (
        <span style={{display:"flex",width:"100%"}}>
            <div style={{height:30,margin:10,backgroundColor:"blue",borderRadius:100,overflow:"hidden"}}> <img src={record.profile_picture} style={{height:"100%"}} /></div>
            <div style={{display:"flex",flexDirection:"column",margin:10}}>
                <p style={{margin:0,padding:0}}>{record.first_name} {record.last_name} ({record.username})</p>
            </div>
        </span>
    );
};

const inputText = (choice: { id: any}) => `${choice.id}`;

    const matchSuggestion = (filter: string, choice: { username: string, first_name: string, last_name :string }) => {
    return (
        choice?.username?.toLowerCase().includes(filter.toLowerCase())|| choice?.first_name?.toLowerCase().includes(filter.toLowerCase())|| choice?.last_name?.toLowerCase().includes(filter.toLowerCase())
    );
    }

const CreateNewUser = () => {
    const { filter, onCancel,onCreate} = useCreateSuggestionContext();
    const redirect = useRedirect();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        redirect(`/users/create`);
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    Click en guardar para ser redigirido a crear usuario
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const matchSuggestionName = (filter: string, choice: {name: string }) => {
    return (
        choice?.name?.toLowerCase().includes(filter.toLowerCase())
    );
    }   
    
    
const CreateTag = () => {
const { filter, onCancel, onCreate } = useCreateSuggestionContext();
const [value, setValue] = React.useState(filter || '');
const [create] = useCreate();

const handleSubmit = (event: any) => {
event.preventDefault();
create(
    'categories',
    {
        data: {
            name: value,
        },                
    },
    {
        onSuccess: (data) => {
            setValue('');
            onCreate(data);
        },
    }
);
};

return (
<Dialog open onClose={onCancel}>
    <form onSubmit={handleSubmit}>
        <DialogContent>
            <MUITextField
                label="Nueva Categoria"
                value={value}
                onChange={event => setValue(event.target.value)}
                autoFocus
            />
        </DialogContent>
        <DialogActions>
            <MUIButton type="submit">Save</MUIButton>
            <MUIButton onClick={onCancel}>Cancel</MUIButton>
        </DialogActions>
    </form>
</Dialog>
);
};


type Props = {
    source: string;
    label?: string;
}

const PlaceInput = (props :Props) => {
    
    interface AddressInfo {
        streetAddress: string;
        locality: string;
        region: string;
        country: string;
      }
      interface MyValueType {
        address: AddressInfo;
        placeId: string;
        name: string;
        url: string;
      }

      const { id, field, fieldState } = useInput(props);
      const [oldValue ,setOldValue] = React.useState(field?.value ? field.value : null);
      const [clicked ,setClicked] = React.useState(false);
      const [newValue ,setNewValue] = React.useState<MyValueType | undefined>(undefined);
    function parseAddress(addressString: string, placeId: string, name: string, url: string): { address: AddressInfo, placeId: string, name: string, url: string } {
        // Crear un elemento div temporal para poder manipular el HTML
        const tempDiv: HTMLDivElement = document.createElement('div');
        tempDiv.innerHTML = addressString;
      
        // Buscar los elementos span con las clases específicas
        const streetAddressElement: HTMLElement | null = tempDiv.querySelector('.street-address');
        const localityElement: HTMLElement | null = tempDiv.querySelector('.locality');
        const regionElement: HTMLElement | null = tempDiv.querySelector('.region');
        const postalCodeElement: HTMLElement | null = tempDiv.querySelector('.postal-code');
        const countryNameElement: HTMLElement | null = tempDiv.querySelector('.country-name');
      
        // Construir un objeto con las propiedades de la dirección
        const address: AddressInfo = {
          streetAddress: streetAddressElement ? streetAddressElement.textContent?.trim() || '' : '',
          locality: localityElement ? localityElement.textContent?.trim() || '' : '',
          region: regionElement ? regionElement.textContent?.trim() || '' : '',
          country: countryNameElement ? countryNameElement.textContent?.trim() || '' : ''
        };
      
        return {
          address: address,
          placeId: placeId,
          name: name,
          url: url
        };
      }
      
      React.useEffect(() => {
        if(newValue){
            field.onChange(newValue)
        }
        }, [newValue]);

    return (
    <div className="LocationBox">
        Ubicacion { clicked ? "actual : " + oldValue.name + ( oldValue.city?   ", "+  oldValue.city : "" )+  (oldValue.region? +   ", " + oldValue.region  : "" )+  (oldValue.country?   ", " + oldValue.country : "") : ""}
        <div className="LocationInput">
         <PinIcon />
         { oldValue && !clicked ?
         <span onClick={() => setClicked(true)}>{oldValue.name + ( oldValue.city?   ", "+  oldValue.city : "" )+  (oldValue.region? +   ", " + oldValue.region  : "" )+  (oldValue.country?   ", " + oldValue.country : "")}</span> 
         :
            <Autocomplete 
                    style={{
                        width: "100%",
                        height: 50,
                        padding: 5,
                        backgroundColor: "#e0e0e3",
                        border:"none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                        borderRadius:"100 100 0 0 ",
                        marginBottom:10,
                      }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                    options={{ types: ["establishment"], fields: ["ALL"] }}
                    onPlaceSelected={(place) =>  {
                            console.log("place",place)
                            const parsed_address = parseAddress(place.adr_address,place.place_id,place.name,place.url)
                            console.log("parsed place" , parsed_address)
                            setNewValue(parsed_address); 
                        }}
        />
        }
        {newValue  && 
        <div onClick={() => {setClicked(false);setNewValue(undefined)} }>
            <CloseIcon />
        </div>
        }
        </div>
    </div>
    );
    };


const RecommendationDetails = () => {
    const record = useRecordContext<Recommendation>();



    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Link del Producto</TableCell>
                            <TableCell>Marca</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <ReferenceField source='product' reference='products'>
                                    <TextField source="link" />
                                </ReferenceField>
                            </TableCell>
                            <TableCell>
                                <TextField source="product.brand" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <HitRecommendation />
                </Card>
            </Grid>

        </Grid>
    );
};

const AddNewRecommendationButton = () => {
    const record = useRecordContext();

    return (
        <Button
            style={{maxWidth:"40px"}}
            component={Link}
            to={{
                pathname: "/recommendations/create",
                state: { record: { post: record.id } }
            }}
        >
            <AddIcon />
        </Button>
    )
};

   

const MakeHotButton = () => {
    const record = useRecordContext();
    const postId = record.id;
    const data = { is_hot: !record.is_hot }; 
    const [update, { isLoading, error }] = useUpdate(
        'posts',
        { id: record.id, data: data, previousData: record }
    );
    const handleClick = () => {
            update()
    };

    return (
        <Button style={{maxWidth:"300px",width:"100%"}}
            onClick={handleClick} // Maneja el evento onClick con la función handleClick
        >
            <BooleanInput label="Marcar Como Hot" source='is_hot'/>    
        </Button>
    );
};


interface MakeExactButton {
    label: string;
}

const MakeExactButton = ({ label }: MakeExactButton) => {
    const record = useRecordContext();
    const [checked, setChecked] = React.useState(record.is_exact)
    const data = { is_exact: !checked };
    const [update, { isLoading, error }] = useUpdate(
        'recommendations',
        { id: record.id, data: data, previousData: record }
    );

    const handleClick = () => {
            setChecked(!checked)
            update()
    };

    return (
        <Button style={{maxWidth:"300px",width:"100%"}}
            onClick={(e) => {e.stopPropagation();handleClick()}} // Maneja el evento onClick con la función handleClick
        >
            <Switch 
                checked={checked}
                value="active"
            />    
        </Button>
    );
};



const PostEdit = () => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data : any) => {
        redirect(`/posts/${data.id}`);
    };


    const validateLength = (value : any) => {
        if (value.length > 1000) {
            return "Texto debe ser de 1000 caracteres  o menos .(Largo Actual :" + value.length + ")";
        }
        return undefined;
    };
   


    return (
    <Edit redirect="1" >
        <TabbedForm>
            <FormTab
                label="CREAR RECOMENDACIONES"
            //sx={{ maxWidth: '40em' }}
            >
                <div style={{display:'flex',flexDirection:"column",gap:"20px", padding:"20px",flexWrap:"wrap"}}>
                        <Contents />
                    
                        <MakeHotButton />
                        <AddNewRecommendationButton />
                        <ReferenceManyField
                            reference="recommendations"
                            target="post"
                            pagination={<Pagination />}
                        >
                            {/*<AddRecommendation />*/}
                            {/*<CreateButton resource="recommendations" />*/}
                            <Datagrid
                                /*sx={{
                                    width: '100%',
                                    '& .column-comment': {
                                        maxWidth: '20em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                }}*/
                                rowClick="expand"
                                expand={<RecommendationDetails />}
                                expandSingle
                                bulkActionButtons={false}
                            >
                                <ReferenceField source='product' reference='products' label="">
                                    <ImageField source="content_file"
                                        sx={{
                                            width: '2%'
                                        }} />
                                </ReferenceField>
                                <ReferenceField label="Producto" source='product' reference='products'>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField label="Marca" source='product' reference='products'>
                                    <ReferenceField source='brand' reference='brands'>
                                        <BrandLinkField source='name' />
                                    </ReferenceField>
                                </ReferenceField>
                                <MakeExactButton label="Recomendacion exacta" />
                                <ReferenceField label="Detalles" source='product' reference='products'>
                                    <ReferenceArrayField label="" reference="details" source="details">
                                        <SingleFieldList>
                                            <ChipField source="value" />
                                        </SingleFieldList>
                                    </ReferenceArrayField>
                                </ReferenceField>
                                <DeleteButton />
                            </Datagrid>
                        </ReferenceManyField>
                    </div>
                
            </FormTab>
            <FormTab
                label="EDITAR POST">
                    <Contents />
                    <h1> EDITAR PUBLICACION </h1>
                
                    Selecciona a que Album(s) pertenece el post
                    <ReferenceArrayInput source="albums" reference="albums">
                        <AutocompleteArrayInput  validate={required()} label="Albums" fullWidth={true}  optionText='name' matchSuggestion={matchSuggestionName} /> 
                    </ReferenceArrayInput>
                    Selecciona a que perfil de usuaria se subira el post. Recuerda que para tendencias existe Tendencias CBC y para post de Oz existe OZ Labs 
                    <ReferenceInput source="user" reference="users" sx={{ }}>
                        <AutocompleteInput validate={required()} sx={{  }}  fullWidth={true} optionText={<OptionRenderer />} inputText={inputText} matchSuggestion={matchSuggestion}  create={<CreateNewUser />}/>
                    </ReferenceInput>
                    <BooleanInput label="Es global?" source="is_global" defaultValue={true}  />
                    <BooleanInput label="Es hot? Al seleccionar esto, la publicacion se vera automaticamente en el feed general" source="is_hot"  />
                    Crea una bajada para el post, esto es muy importante para Tendencias CBC.Puedes agregas titulos,link y mas.
                    <RichTextInput source="description" validate={[required(),validateLength]} />
                    Selecciona a que categoria pertenece el post, si piensas en una mejor, creala.
                    <ReferenceArrayInput source="categories" reference="categories">
                        <AutocompleteArrayInput label="categories" fullWidth={true}   optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateTag />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear categorias ',}} /> 
                    </ReferenceArrayInput>
                    Si el post pertenece a travel, debes agregar una nota sobre la experiencia del lugar, siendo 1 la peor experiencia  5 la mejor.
                    <NumberInput source="grade"  min={0} max={5} step={0.5} helperText={"Solo completar para travel"}/>
                    Si el post pertenece a travel , o es un evento, debes ingresar una ubicacion. Estos son lugares, se especifico. Se guardara automaticamente el pais y la ciudad.
                    <PlaceInput source="place" label="Place"   />
                     
            </FormTab>
        </TabbedForm>
    </Edit>
    )
                };

const req = [required()];



export default PostEdit;