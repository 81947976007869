import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
    useGetList,
} from 'react-admin';

import { Color, GarmentType } from '../types';

const Aside = () => {
    const { data: colors } = useGetList<Color>('colors', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });

    const { data: garment_types } = useGetList<GarmentType>('garmenttypes', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <SavedQueriesList />

                <FilterList
                    label="resources.products.filters.colors"
                    icon={<LocalOfferIcon />}
                >
                    {colors &&
                        colors.map((record: any) => (
                            <FilterListItem
                                label={inflection.humanize(record.name)}
                                key={record.id}
                                value={{ color: record.id }}
                            />
                        ))}
                </FilterList>

                <FilterList
                    label="resources.products.filters.garmenttypes"
                    icon={<LocalOfferIcon />}
                >
                    {garment_types &&
                        garment_types.map((record: any) => (
                            <FilterListItem
                                label={inflection.humanize(record.name)}
                                key={record.id}
                                value={{ garment: record.id }}
                            />
                        ))}
                </FilterList>
            </CardContent>
        </Card>
    );
};

export default Aside;