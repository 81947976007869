import * as React from 'react';
import { Link, FieldProps, useRecordContext } from 'react-admin';

import FullNameField from './FullNameField';
import { User } from '../types';

const OldUserLinkField = (props: FieldProps<User>) => {
    const record = useRecordContext<User>();
    if (!record) {
        return null;
    }
    return (
        <>
            {
                !record.is_migrated ?
                    (
                        <Link to={`/oldusers/${record.id}`}>
                            <FullNameField />
                        </Link>
                    )
                    :
                    <FullNameField />
            }

        </>


    );
};

OldUserLinkField.defaultProps = {
    source: 'customer',
};

export default OldUserLinkField;