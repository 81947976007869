import * as React from 'react';
import {
    Datagrid,
    List,
    ReferenceInput,
    SelectInput,
    TextInput,
    TopToolbar,
    SortButton,
    FilterButton,
    Button,
    TextField,
    BooleanField
} from 'react-admin';
import { useMediaQuery, useTheme } from '@mui/material';
import OldUserLinkField from './OldUserLinkField';
import { MailOutline } from '@mui/icons-material';
import UserListAside from './UserListAside';

const userFilters = [
    <TextInput source="country" />,
    <ReferenceInput
        source="roles"
        reference="roles"
        sort={{ field: 'id', order: 'ASC' }}
    >
        <SelectInput source="name" />
    </ReferenceInput>,
];

const OldUserList = () => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const RowButton =  (selectedUser: any) => {
    //console.log(selectedUser);
    
    // const [create, { isLoading, error }] = useCreate('assignations', { data: {user:selectedUser.selectedUser.id, benefit:benefit.benefit.id} });
    const handleClick = (e : any, id : any) => {
        e.stopPropagation();
        // TODO : funcion invitar con API /invite 
        
    };

    if (!selectedUser.selectedUser.is_active){
        return(
            <Button 
                    children={<div style={{display:"flex",flexDirection:"row",margin:0,padding:0,}}><MailOutline /> 
                    <p style={{fontSize:10,margin:0,padding:0}}>Enviar activación cuenta</p></div>}
                    onClick={(e) => handleClick(e,selectedUser.id)}
             />
        )
    }
    else {
        return (
            <div/>
        )
    }
}



    return (
        <List
            filters={isSmall ? userFilters : undefined}
            sort={{ field: 'last_seen', order: 'DESC' }}
            perPage={25}
            aside={<UserListAside />}
            actions={<ListActions isSmall={isSmall} />}
        >
            
                <Datagrid
                    optimized
                    // rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                >
                    <OldUserLinkField  />
                    <BooleanField source= "is_migrated" />
                    <TextField source="country" />
                    <TextField source="username" />
                    <TextField source="email" />
                    <TextField source="phone" />

                </Datagrid>
         
        </List>
    );
};



const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        <SortButton fields={['created_at', ]} />
    </TopToolbar>
);

export default OldUserList;
