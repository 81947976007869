import * as React from 'react';
import { Card, CardMedia } from '@mui/material';


const Content = (record: any) => {
    return (
        <Card sx={{ display: 'inline-block' }}>
            <CardMedia
                component='img'
                image={record?.content_file}
                alt=""
                //sx={{ maxWidth: '42em', maxHeight: '15em' }}
            />
        </Card>
    );
};


export default Content;