import React from 'react';
import { Box, Chip, useMediaQuery, Theme, useTheme } from '@mui/material';
import {
    CreateButton,
    ExportButton,
    FilterButton,
    FilterForm,
    FilterContext,
    InputProps,
    ListBase,
    NumberInput,
    Pagination,
    ReferenceInput,
    SearchInput,
    SelectInput,
    SortButton,
    Title,
    TopToolbar,
    useTranslate,
    useGetResourceLabel,
} from 'react-admin'

import ImageList from './GridList';
import Aside from './Aside';

/*
const PostList = (props: object) => {
    return <List {...props}>
        <Datagrid>
            <TextField source='id' />
            <TextField source='description' />
            <DateField source='created_at' />
        </Datagrid>
    </List>
}
*/
const PostList = () => {
    const getResourceLabel = useGetResourceLabel();
    //const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <ListBase perPage={24} sort={{ field: 'created_at', order: 'DESC'}}>
        <Title defaultTitle={getResourceLabel('posts', 2)} />
        <FilterContext.Provider value={productFilters}>
                <ListActions isSmall={isSmall} />
                {isSmall && (
                    <Box m={1}> 
                        <FilterForm />
                    </Box>
                )}
            </FilterContext.Provider>
            <Box display="flex">
                <Aside />
                <Box width={isSmall ? 'auto' : 'calc(100% - 16em)'}>
                    <ImageList />
                    <Pagination rowsPerPageOptions={[12, 24, 48, 72]} />
                </Box>
            </Box>
        </ListBase>
    )
}

const QuickFilter = ({ label }: InputProps) => {
    const translate = useTranslate();
    return <Chip sx={{ mb: 1 }} label={translate(label as string)} />;
};

export const productFilters = [
    // <SearchInput source="q" alwaysOn />,
    // <ReferenceInput
    //     source="category_id"
    //     reference="categories"
    //     sort={{ field: 'id', order: 'ASC' }}
    // >
    //     <SelectInput source="name" />
    // </ReferenceInput>,
    // <NumberInput source="width_gte" />,
    // <NumberInput source="width_lte" />,
    // <NumberInput source="height_gte" />,
    // <NumberInput source="height_lte" />,
    // <QuickFilter
    //     label="resources.products.fields.stock_lte"
    //     source="stock_lte"
    //     defaultValue={10}
    // />,
];

const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        <SortButton fields={['created_at', 'date', 'id']} />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export default PostList;