import React, { useState, useRef,useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import Slider from "@mui/material/Slider";
import CloseIcon from "./SVG/CloseIcon";

export default function PhotoEditor(props) {
  const setShow = props.setShow;
  const image = props.image;
  const setImage = props.setImage;
  const editor = useRef();
  const setNewImage=props.setNewImage;
  const size = 250;
  const index = props.index;
  const height = Math.round((size / 3) * 4);
  const ratioSquare = props.ratioSquare;
  const [valueScale, setValueScale] = React.useState(50);




  function handleChangeScale(event, newValue) {
    setValueScale(newValue);
  }

  const handleClick = () => {
    //const image = editor.current.getImageScaledToCanvas().toDataURL();
    const newImage = editor.current.getImageScaledToCanvas();
    // Convertir la imagen del lienzo en un Blob
    newImage.toBlob(blob => {
    // Crear un nuevo objeto File a partir del Blob
    const file = new File([blob], image.content_name, { type: image.content_type });
    setNewImage(index, file);
    });
    if (newImage !== null) {
      setShow(false);
    }
  }



  return (
    <div className="ModalCenteredLayout" onClick={() => setShow(false)}>
        <div className="ModalCenteredBox" onClick={(e) => e.stopPropagation()}>
           <button className="ModalCloseButton" onClick={(e) => { e.preventDefault(); setShow(false) }}>
             <CloseIcon width="20px" height="20px" />
           </button>
           <div className="ModalTitle"> </div>
           <div className="ModalBody">
              <div className="EditPhotoBox">
                <AvatarEditor
                  width={size}
                  height={ratioSquare ? size : height}
                  image={image?.content_file}
                  ref={editor}
                  border={10}
                  scale={valueScale / 50}
                  rotate={50 * 3.6 - 180}
                />
              </div>
              <Slider
                aria-label="Scale"
                value={valueScale}
                onChange={handleChangeScale}
              />
          </div>
          <button className="ModalButton" onClick={handleClick}>
            Guardar
          </button>
          <button className="ModalSecondaryButton" onClick={(e) => { e.preventDefault(); setShow(false); setImage(false) }}>Volver</button>
        </div>
      </div>
  );
}
