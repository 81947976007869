import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Carousel.css";
import CarouselContentUpload from "./CarouselContentUpload";

//Icons
import CloseIcon from "./SVG/CloseIcon";
import ResizeIcon from "./SVG/ResizeIcon";


export default function AddPhotos(props) {
  const navigate = useNavigate();
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "MP4"];
  const [isEditMode,setIsEditMode] = useState(false);
  const ratioSquare = props.ratioSquare;
  const setRadioSquare = props.setRadioSquare;
  const files = props.files;
  const setFiles = props.setFiles;
  const [originalFiles, setOriginalFiles] = useState([]) ; 

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length <= 7) {
      setFiles(selectedFiles);
      setOriginalFiles(selectedFiles);
    } else {
      alert("Solo se permiten hasta 7 archivos");
      // Limpiar la selección si se excede el límite
      event.target.value = null;
    }
  };

  const formatFiles = (files) => {
    return files.map((file) => ({
      content_file: URL.createObjectURL(file),
      content_type: file.type,
      content_name : file.name
    }));
  };

  const onRemoveImage = (i) => {
    setFiles(prev => prev.filter((item, index) => index !== i));
    setOriginalFiles(prev => prev.filter((item, index) => index !== i));
    
  };

  const setNewImage = (i,newValue) => {
    setFiles(prev => prev.map((item, index) => index === i ? newValue : item));
  };

  const onError = (error) => {
    console.error(error);
  };



  return (
          <div className="UploadContentBox">
            { files.length > 0  ?
                <div className="PhotoPlacement" style={{height: ratioSquare ? "260px": "347px"}}>
                  <CarouselContentUpload data={formatFiles(files)} og_data={formatFiles(originalFiles)} onRemoveImage={onRemoveImage} setNewImage={setNewImage} size={260} ratioSquare={ratioSquare} isUpload={true} mini={false} />
                </ div>
              :
              <div className="PhotoPlacement" style={{height: ratioSquare ? "260px": "347px"}}>
                  <div className="UploadButton">
                  <input
                    id="fileInput"
                    type="file"
                    accept="image/*, video/*"
                    multiple
                    onChange={handleFileChange}
                    style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
                  />
                  <label className="UploadLabel" htmlFor="fileInput" style={{ cursor: 'pointer' }}>Subir Multimedia</label>
                </div>
              </ div>
            }
            <button className="FloatingCarouselButton" onClick={(e) => {e.preventDefault(); setRadioSquare(!ratioSquare)}}>
              <ResizeIcon />
            </button>
          </div>
  );
}
