import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BenefitsList from './BenefitsList';
import BenefitsCreate from './BenefitsCreate';
import BenefitsEdit from './BenefitsEdit';

export default {
    list: BenefitsList,
    create: BenefitsCreate,
    edit: BenefitsEdit,
    icon: DynamicFeedIcon,
}