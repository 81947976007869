import { WorkspacePremium } from '@mui/icons-material';
import React from 'react'
import { ArrayField,  Button, ChipField, Datagrid,FunctionField, List, NumberField,  SingleFieldList, TextField, TopToolbar, useCreate, useRefresh, useListContext, useUnselectAll, ReferenceArrayField,} from 'react-admin';
import assignations from '../assignations';
import { Card, CardContent } from '@mui/material';
import inflection from 'inflection';
import Aside from '../users/UserListAside'

export default function UserSelect( benefit : any ) {
  const UsersSelectInput = ({source, reference, benefit }  : {source: any ;reference:any; benefit:any}) => {
    const ListActions = () => (
    <TopToolbar>
        {/* <FilterButton/> */}
    </TopToolbar>
);
    



const RowButton =  (selectedUser: any) => {
    const [create, { isLoading, error }] = useCreate('assignations', { data: {user:selectedUser.selectedUser.id, benefit:benefit.benefit.id} });
    const handleClick = () => {
        create();
    };
    if (benefit.benefit.users.includes(selectedUser.selectedUser.id)){
        return <div/>
    }
    else{ 
        return (
            <Button 
                    children={<div style={{display:"flex",flexDirection:"row",margin:0,padding:0,}}><WorkspacePremium /> 
                    <p style={{fontSize:10,margin:0,padding:0}}>Asignar Beneficio </p></div>}
                    onClick={() => handleClick()}
                    />
 )  

    }

 
}

const BulkRowButton =  ()  => {
    const { selectedIds } = useListContext();
    const unselectAll = useUnselectAll('users');
    const [create, { isLoading, error }] = useCreate();
    const refresh = useRefresh()
    const handleClick = () => {
        for (var val of  selectedIds){
             if (! benefit.benefit.users.includes(val)){
                create('assignations',{ data: {user:val, benefit:benefit.benefit.id} }); 
             }
    }  
    };

    
    return (
       <Button 
                    children={<div style={{display:"flex",flexDirection:"row",margin:0,padding:0,alignItems:"center",justifyContent:"center"}}><WorkspacePremium /> 
                    <p style={{fontSize:10,margin:0,padding:0}}>Asignar Beneficio a Seleccionados </p></div>}
                    onClick={() => handleClick()}
                    />
    );
};
   
    const PostBulkActionButtons = () => (
    <>
        <BulkRowButton />
        {/* default bulk delete action */}
        {/* <BulkDeleteButton /> */}
    </>
);
    return (
        <List resource="users" actions={<ListActions/>} filter={{ ids_not_in: benefit.benefit.users }}  >
        <Datagrid
                    optimized
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                    bulkActionButtons={<PostBulkActionButtons />}
                >
                    <NumberField source="id" /> 
                    <TextField source="first_name" />
                    <TextField source="last_name" />
                    <ReferenceArrayField label="Roles" reference="roles" source="roles">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                    </ReferenceArrayField>
                    <ReferenceArrayField label="Segmentos" reference="segments" source="segments">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                    </ReferenceArrayField>
                    <FunctionField render={(record: any) => <RowButton selectedUser={record}/> } />
                </Datagrid>
        </List>
    );
    
    };

    return(
        <UsersSelectInput source="users" reference="soemthihng" benefit={benefit} /> 
    );
}
