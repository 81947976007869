import * as React from 'react';
import { SVGProps } from 'react';
import { useTheme } from '@mui/material/styles';

const Logo = (props: SVGProps<SVGSVGElement>) => {
    const theme = useTheme();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 411.1 527.72" 
            className="r-1ujkv8a r-1elbsa5 r-1gvwngo r-1ih7r1r"
            height={20.475}
            >
            <defs></defs>
            <g id="Capa_2" data-name="Capa 2">
                <g id="Capa_1-2" data-name="Capa 1">
                    <path className="cls-1" d="M398.34 165.58q-7.39-22.15-17.8-46.67a191.49 191.49 0 0 0-26.86-45 131.75 131.75 0 0 0-41-33.58q-24.53-13.13-60.77-13.11-49 0-77.26 18.12T132 95.73q-14.46 32.27-18.49 76.91t-4 97.75q0 69.84 8.06 115.54t25.18 72.54q17.14 26.85 44.33 37.62t64.84 10.74q30.22 0 54.74-12.08A152 152 0 0 0 350 462.83a186.18 186.18 0 0 0 31.58-45.34 228.12 228.12 0 0 0 18.81-53.06h10.71v142.4h-10.74c-2.25-5.37-4.93-9.05-8.07-11.08s-7.83-3-14.11-3q-8.06 0-20.82 3.69t-29.21 8.07q-16.48 4.33-35.95 8.06a220.71 220.71 0 0 1-41 3.68 242.77 242.77 0 0 1-94.72-19.13A256.31 256.31 0 0 1 20.82 366.44Q0 321.44 0 269q0-57 23.17-103.42t59.45-79.25a266 266 0 0 1 80.94-50.71q44.69-17.82 87.67-17.81a187.33 187.33 0 0 1 40 4q18.46 4.05 34.26 8.39T354 38.63q12.75 4 21.49 4 9.4 0 14.1-2.36t6.72-11.76h10.76v137Z"></path>
                    <path className="cls-1" d="m135.2 527.72-8.93-3.54L335.08 0l8.93 3.56L135.2 527.72z"></path>
                </g>
            </g>
        </svg>
    );
};

export default Logo;