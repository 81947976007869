import * as React from 'react';
import { Box, Button, Chip, useMediaQuery, useTheme } from '@mui/material';
import {
    List,
    Title,
    useGetResourceLabel,
    Datagrid,
    DateField,
    TextField,
    UrlField,
    TopToolbar,
    SortButton,
    ExportButton,
    FilterButton,
    CreateButton,
    BooleanField,
    useUpdateMany,
    useListContext,
    useUnselectAll,
    useRefresh,
    BulkDeleteButton,
    
    
} from 'react-admin';






const BrandList = () => {
    const theme = useTheme();
    const isXsmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const getResourceLabel = useGetResourceLabel();




    const BulkRowButton =  ()  => {
        const { selectedIds } = useListContext();
        const unselectAll = useUnselectAll('brands');
        const [update, { isLoading, error }] = useUpdateMany();
        const refresh = useRefresh()
        const handleClick = () => {
            update(
                'brands',
                { ids: selectedIds, data: { is_partner: true } }
            )
        };
    
        
        return (
           <Button 
                        children={<div style={{display:"flex",flexDirection:"row",margin:0,padding:0,alignItems:"center",justifyContent:"center"}}>
                        <p style={{fontSize:10,margin:0,padding:0}}>Convertir a Partner </p></div>}
                        onClick={() => handleClick()}
                        />
        );
    };
       
        const BrandBulkActionButtons = () => (
        <>
            <BulkRowButton />
            <BulkDeleteButton /> 
        </>
    );

    return (
        <List sort={{ field: 'reference', order: 'ASC' }}
            perPage={25}
            actions={<ListActions isSmall={isSmall} />}
            >

                      <Datagrid
                            optimized
                            rowClick="edit"
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                            bulkActionButtons={BrandBulkActionButtons()}
                        >
                        <TextField source="name"/>
                        <DateField source="created_at" />
                        <UrlField source="link" />
                        <BooleanField source ="is_partner"/>    
                        </Datagrid>
       
        </List>
    );
};



const ListActions = ({ isSmall }: any) => (
    <TopToolbar sx={{ minHeight: { sm: 56 } }}>
        {isSmall && <FilterButton />}
        <SortButton fields={['created_at', ]} />
        <ExportButton />
        <CreateButton />
    </TopToolbar>
);


export default BrandList;
