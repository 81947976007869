import React, { useState } from "react";
import Carousel from "react-simply-carousel";
import { useNavigate } from "react-router-dom";
import "./Carousel.css";

import VideoWithTimer from "./VideoPlayer";

//Icons
import CarouselDotIcon from "./SVG/CarouselDotIcon";


function CarouselContent(props) {
  const size = props.size;
  const ratioSquare = props.ratioSquare;
  const mini = props.mini;
  const id = props.id;
  const username = props.username;
  const isUpload = props.isUpload;
  const [activeSlide, setActiveSlide] = useState(0);
  const data = props.data;
  const navigate = useNavigate();

  const handleDotClick = (e,index) => {
    e.preventDefault();
    setActiveSlide(index);
  };

  
  return (
    <div>
      <div
        className="Carousel"
        style={{
          minWidth: size + "px",
          minHeight: ratioSquare ? size + "px" : Math.round((size / 3) * 4) + "px",
          width: size + "px",
          height: ratioSquare ? size + "px" : Math.round((size / 3) * 4) + "px",
          backgroundColor:"grey"
        }}
      >
        {size ? (
          <Carousel
            containerProps={{
              className: "CarouselContainer",
              style: {
                width: size + "px",
                height: ratioSquare
                  ? size + "px"
                  : Math.round((size / 3) * 4) + "px",
              },
            }}
            activeSlideIndex={activeSlide}
            activeSlideProps={{
              className: "ActiveSlide",
            }}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: "",
              style: {
                width: 0,
                height: 0,
                minWidth: 0,
                alignSelf: "center",
                border: "none",
                background: "none",
              },
            }}
            backwardBtnProps={{
              children: false,
              style: {
                width: 0,
                height: 0,
                minWidth: 0,
                alignSelf: "center",
                border: "none",
                background: "none",
              },
            }}
            dotsNav={{
              show: false,
              itemBtnProps: {
                style: {
                  height: 0,
                  width: 0,
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 0,
                  width: 0,
                },
              },
            }}
            centerMode={true}
            itemsToShow={1}
            speed={400}
          >
            {data ? (
              data.map((content, index) => {
                return (
                  <div
                    className="ContentButton"
                    style={{
                      width: size + "px",
                      height: ratioSquare
                        ? size + "px "
                        : Math.round((size / 3) * 4) + "px",
                    }}
                    key={index}
                  >
                    {content.content_type &&
                    content.content_type.includes("image") ? (
                      <div
                        style={{
                          height: ratioSquare
                            ? size + "px "
                            : Math.round((size / 3) * 4) + "px",
                          width: size + "px",
                          backgroundImage: `url(${content.content_file})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    ) : (
                      <VideoWithTimer
                        url={content.content_file}
                        width={size}
                        height={ratioSquare ? size : Math.round((size / 3) * 4)}
                        mini={mini}
                        isPlaying={index === activeSlide}
                        isUpload={isUpload}
                      />
                    )}
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </Carousel>
        ) : (
          <div />
        )}
      </div>

      {data && data.length > 1  ? (
      <div className="DotWrapper">
      {mini && <div className={"CarouselDotBar Mini"}>
       {data.map((_, index) => (
         (Math.abs(activeSlide-index)<3) || (activeSlide < 2 && index < 5 ) || (activeSlide >= data.length - 2 && index >= data.length - 5) ? (
           <button 
             className="CarouselDot"
             key={index}
             onClick={(e) =>{e.preventDefault(e);handleDotClick(index)}}
           >
             <CarouselDotIcon
               active={index === activeSlide}
               activeColor={"#FFFFFF"}
               normalColor={"rgba(255,255,255,0.25)"}
             />
           </button>
         ) : null
       ))}
        </div>}

      {!mini && <div className={"CarouselDotBar"}>
       {data.map((_, index) => (
         (Math.abs(activeSlide-index)<3) || (activeSlide < 2 && index < 5 ) || (activeSlide >= data.length - 2 && index >= data.length - 5) ? (
           <button 
             className="CarouselDot"
             key={index}
             onClick={(e) => handleDotClick(e,index)}
           >
             <CarouselDotIcon
               active={index === activeSlide}
               size={
                 Math.abs(index - activeSlide) <=1
                   ? "normal"
                   : Math.abs(index - activeSlide) <=3
                   ? "small"
                   : "smaller"
               }
             />
           </button>
         ) : null
       ))}
        </div>}

      </div>
     
      ) : (
        <></>
      )}
    </div>
  );
}

export default CarouselContent;
