import { Admin, CustomRoutes, ListGuesser, Resource } from 'react-admin'
import { Route } from 'react-router';
import { default as jwtAuthProvider, fetchJsonWithAuthJWTToken } from './authProvider'
import { default as drfDataProvider } from './dataProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import spanishMessages from './i18n/es';
import { Login, Layout } from './layout'
//import { Dashboard } from './dashboard'
import { lightTheme } from './layout/themes'
import Configuration from './configuration/Configuration';
import users from './users'
import posts from './posts'
import recommendations from './recommendations';
import products from './products';
import brands from './brands';
import benefits from './benefits';
import assignations from './assignations';
import segments from './segments';
import oldlooks from './oldlooks'
import oldusers from './oldusers';

import "./App.css"

const dataProvider = drfDataProvider(fetchJsonWithAuthJWTToken);
const authProvider = jwtAuthProvider();

const i18nProvider = polyglotI18nProvider(locale => {
  if (locale === 'en') {
    return import('./i18n/en').then(messages => messages.default);
  }
  // Always fallback on spanish
  return spanishMessages;
}, 'es');

function App() {
  return <Admin
    loginPage={Login}
    authProvider={authProvider}
    dataProvider={dataProvider}
    i18nProvider={i18nProvider}
    layout={Layout}
    theme={lightTheme}
    disableTelemetry
  >
    <Resource name="posts" {...posts} />
    <Resource name="users" {...users} />
    <Resource name="products" {...products} recordRepresentation="name" />
    <Resource name="brands" {...brands} recordRepresentation="name" />
    <Resource name="recommendations" {...recommendations} />
    <Resource name="benefits" {...benefits} />
    {/* <Resource name="assignations" {...assignations} /> */}
    <Resource name="oldlooks" {...oldlooks} />
    <Resource name="oldusers" {...oldusers} />
    <CustomRoutes>
      <Route path="/configuration" element={<Configuration />} />
    </CustomRoutes>
  </Admin>
}

export default App;
