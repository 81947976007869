import * as React from 'react';
import { Create,useNotify, FormTab, TabbedForm, TextInput,BooleanInput,SelectInput, required, ImageInput, ImageField, ReferenceInput, AutocompleteArrayInput,useCreateSuggestionContext,
    useCreate, } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { useNavigate } from 'react-router';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';




const UserCreate = () => {
    const navigate = useNavigate();
    const notify = useNotify();

    const onSuccess = () => {
        notify(`Changes saved`);
        navigate(-1);
    };



    const matchSuggestionName = (filter: string, choice: {name: string }) => {
        return (
            choice?.name?.toLowerCase().includes(filter.toLowerCase())
        );
        }   


        const CreateSegments = () => {
            const { filter, onCancel, onCreate } = useCreateSuggestionContext();
            const [value, setValue] = React.useState(filter || '');
            const [create] = useCreate();
        
            const handleSubmit = (event: any) => {
                event.preventDefault();
                create(
                    'segments',
                    {
                        data: {
                            name: value,
                        },                
                    },
                    {
                        onSuccess: (data) => {
                            setValue('');
                            onCreate(data);
                        },
                    }
                );
            };
        
            return (
                <Dialog open onClose={onCancel}>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <MUITextField
                                label="Nuevo  Segmento "
                                value={value}
                                onChange={event => setValue(event.target.value)}
                                autoFocus
                            />
                        </DialogContent>
                        <DialogActions>
                            <MUIButton type="submit">Save</MUIButton>
                            <MUIButton onClick={onCancel}>Cancel</MUIButton>
                        </DialogActions>
                    </form>
                </Dialog>
            );
        };


        const CreateRoles = () => {
            const { filter, onCancel, onCreate } = useCreateSuggestionContext();
            const [value, setValue] = React.useState(filter || '');
            const [create] = useCreate();
        
            const handleSubmit = (event: any) => {
                event.preventDefault();
                create(
                    'roles',
                    {
                        data: {
                            name: value,
                        },                
                    },
                    {
                        onSuccess: (data) => {
                            setValue('');
                            onCreate(data);
                        },
                    }
                );
            };
        
            return (
                <Dialog open onClose={onCancel}>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <MUITextField
                                label="Nuevo  Rol "
                                value={value}
                                onChange={event => setValue(event.target.value)}
                                autoFocus
                            />
                        </DialogContent>
                        <DialogActions>
                            <MUIButton type="submit">Save</MUIButton>
                            <MUIButton onClick={onCancel}>Cancel</MUIButton>
                        </DialogActions>
                    </form>
                </Dialog>
            );
        };

    return (
        <Create mutationOptions={{ onSuccess }}>
            <TabbedForm defaultValues={{ store: 1 }}>
                <FormTab
                    label="resources.products.tabs.image"
                    sx={{ maxWidth: '40em' }}
                >
                   
                    <TextInput source="username" validate={required()} />
                    <TextInput source="first_name" validate={required()} />
                    <TextInput source="last_name" validate={required()} />
                    <TextInput source="email" validate={required()} />
                    <TextInput source="phone" validate={required()} />
                    <TextInput source="ig_user"/>
                    <TextInput source="tiktok_user" />
                    <BooleanInput source="is_staff"/>
                    <TextInput source="_oldUser" />
                    <SelectInput
                        source="country" choices={[{id:"CL",name:"Chile"}]} validate={required()}/>
                    <ReferenceInput source="segments" reference="segments" sx={{ }}>
                        <AutocompleteArrayInput  sx={{  }}  fullWidth={true}  optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateSegments />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear segmentos ',}}/>
                    </ReferenceInput>
                    <ReferenceInput source="roles" reference="roles" sx={{ }}>
                        <AutocompleteArrayInput  sx={{  }}  fullWidth={true}  optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateRoles />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear roles ',}} />
                    </ReferenceInput>
                    <ImageInput source="profile_picture">
                        <ImageField source="src" />
                    </ImageInput>
                </FormTab>
            </TabbedForm>
        </Create>
    );
};



export default UserCreate;