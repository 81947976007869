import * as React from 'react';
import { useInput,Create,SimpleFormIterator,useRedirect, FormTab, TabbedForm, TextInput,BooleanInput,SelectInput,NumberInput,SelectArrayInput, required, FileInput, FileField, ReferenceInput, AutocompleteInput, useRecordContext,useGetOne, RaRecord, UseRecordContextParams,useCreateSuggestionContext,
    useCreate,ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
// import { PreviewVideos } from './ContentCarousel';
import Autocomplete from "react-google-autocomplete";
import { TagsInput } from "react-tag-input-component";
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import AddPhotos from './Contents/AddPhotos';



const PostCreate = () => {
    
    const OptionRenderer = () => {
    const record = useRecordContext();
    return (
        <span style={{display:"flex",width:"100%"}}>
            <div style={{height:30,margin:10,backgroundColor:"blue",borderRadius:100,overflow:"hidden"}}> <img src={record.profile_picture} style={{height:"100%"}} /></div>
            <div style={{display:"flex",flexDirection:"column",margin:10}}>
                <p style={{margin:0,padding:0}}>{record.first_name} {record.last_name} ({record.username})</p>
            </div>
        </span>
    );
};

    const inputText = (choice: { id: any}) => `${choice.id}`;

    const matchSuggestion = (filter: string, choice: { username: string, first_name: string, last_name :string }) => {
    return (
        choice?.username?.toLowerCase().includes(filter.toLowerCase())|| choice?.first_name?.toLowerCase().includes(filter.toLowerCase())|| choice?.last_name?.toLowerCase().includes(filter.toLowerCase())
    );
    }

    
    const ContentPreviewField = (props: any) => {
    const record = useRecordContext(props);
    //console.log(record);
    if (record.rawFile?.type.includes("image")){
        return (<img src={record.src} width={250}/>);
    }
    else if (record.rawFile?.type.includes("video")){
        // return (<PreviewVideos item={record} image_size={250}/>)
        return (<div/>);
    }
    else {
        return (<div/>);
    }
    }


    type Props = {
        source: string;
        label?: string;
    }

  const PlaceInput = (props :Props) => {
    const { id, field, fieldState } = useInput(props);
    interface AddressInfo {
        streetAddress: string;
        locality: string;
        region: string;
        country: string;
      }
      
    function parseAddress(addressString: string, placeId: string, name: string, url: string): { address: AddressInfo, placeId: string, name: string, url: string } {
        // Crear un elemento div temporal para poder manipular el HTML
        const tempDiv: HTMLDivElement = document.createElement('div');
        tempDiv.innerHTML = addressString;
      
        // Buscar los elementos span con las clases específicas
        const streetAddressElement: HTMLElement | null = tempDiv.querySelector('.street-address');
        const localityElement: HTMLElement | null = tempDiv.querySelector('.locality');
        const regionElement: HTMLElement | null = tempDiv.querySelector('.region');
        const postalCodeElement: HTMLElement | null = tempDiv.querySelector('.postal-code');
        const countryNameElement: HTMLElement | null = tempDiv.querySelector('.country-name');
      
        // Construir un objeto con las propiedades de la dirección
        const address: AddressInfo = {
          streetAddress: streetAddressElement ? streetAddressElement.textContent?.trim() || '' : '',
          locality: localityElement ? localityElement.textContent?.trim() || '' : '',
          region: regionElement ? regionElement.textContent?.trim() || '' : '',
          country: countryNameElement ? countryNameElement.textContent?.trim() || '' : ''
        };
      
        return {
          address: address,
          placeId: placeId,
          name: name,
          url: url
        };
      }
      
    return (
        <Autocomplete 
                    style={{
                        width: "100%",
                        height: 50,
                        padding: 5,
                        backgroundColor: "#e0e0e3",
                        border:"none",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                        borderRadius:"100 100 0 0 ",
                        marginBottom:10,
                      }}
                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                    options={{ types: ["establishment"], fields: ["ALL"] }}
                    onPlaceSelected={(place) =>  {
                            console.log("place",place)
                            const parsed_address = parseAddress(place.adr_address,place.place_id,place.name,place.url)
                            console.log("parsed place" , parsed_address)
                            field.onChange(parsed_address); 
                        }}
        />
    );
    };



    const ContentInput = (props :Props) => {
        const { id, field, fieldState } = useInput(props);
        const [files, setFiles] = React.useState([]);
        const [ratioSquare,setRadioSquare]=React.useState(false);

        React.useEffect(() => {
            // Esta función se ejecutará cada vez que el estado 'files' cambie
            console.log(files)
            field.onChange({files : files, ratioSquare : ratioSquare});
        }, [files,ratioSquare]); // 'files' es la dependencia de este efecto
        return (
        <div style={{width:"100%",margin:"30px"}}>
        <AddPhotos files={files} setFiles={setFiles} ratioSquare={ratioSquare} setRadioSquare={setRadioSquare} />
        </div>
        )
        }




    const CreateNewUser = () => {
        const { filter, onCancel,onCreate} = useCreateSuggestionContext();
        const redirect = useRedirect();
    
        const handleSubmit = (event: any) => {
            event.preventDefault();
            redirect(`/users/create`);
        };
    
        return (
            <Dialog open onClose={onCancel}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        Click en guardar para ser redigirido a crear usuario
                    </DialogContent>
                    <DialogActions>
                        <MUIButton type="submit">Save</MUIButton>
                        <MUIButton onClick={onCancel}>Cancel</MUIButton>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };

    const matchSuggestionName = (filter: string, choice: {name: string }) => {
        return (
            choice?.name?.toLowerCase().includes(filter.toLowerCase())
        );
        }   
        
        
const CreateTag = () => {
const { filter, onCancel, onCreate } = useCreateSuggestionContext();
const [value, setValue] = React.useState(filter || '');
const [create] = useCreate();

const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
        'categories',
        {
            data: {
                name: value,
            },                
        },
        {
            onSuccess: (data) => {
                setValue('');
                onCreate(data);
            },
        }
    );
};

return (
    <Dialog open onClose={onCancel}>
        <form onSubmit={handleSubmit}>
            <DialogContent>
                <MUITextField
                    label="Nueva Categoria"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    autoFocus
                />
            </DialogContent>
            <DialogActions>
                <MUIButton type="submit">Save</MUIButton>
                <MUIButton onClick={onCancel}>Cancel</MUIButton>
            </DialogActions>
        </form>
    </Dialog>
);
};


const validateLength = (value : any) => {
    if (value.length > 1000) {
        return "Texto debe ser de 1000 caracteres  o menos .(Largo Actual :" + value.length + ")";
    }
    return undefined;
};


    return (
        <Create>
            <TabbedForm >
                <FormTab
                    label="POST"
                    sx={{ maxWidth: '40em' }}
                >
                    <h1> NUEVA PUBLICACIÓN </h1>
                    Sube el contenido de tu publicacion. Pueden ser videos y fotos (Max. total 60MB)
                   
                    {/* <FileInput source="contents" validate={required()} multiple sx={{"& .RaFileInput":{width:300
                    }, display:"flex",flexDirection:"row",flexFlow:"wrap","& .RaFileInput-dropZone":{
                        width:"100%"
                    }}}
                    >
                             <FileField source="src" title="title" />
                    </FileInput> */}

                    <ContentInput source="contents" label="contents" />
                   
                    Selecciona a que Album(s) pertenece el post
                    <ReferenceArrayInput source="albums" reference="albums">
                        <AutocompleteArrayInput  validate={required()} label="Albums" fullWidth={true}  optionText='name' matchSuggestion={matchSuggestionName} /> 
                    </ReferenceArrayInput>
                    Selecciona a que perfil de usuaria se subira el post. Recuerda que para tendencias existe Tendencias CBC y para post de Oz existe OZ Labs 
                    <ReferenceInput source="user" reference="users" sx={{ }}>
                        <AutocompleteInput validate={required()} sx={{  }}  fullWidth={true} optionText={<OptionRenderer />} inputText={inputText} matchSuggestion={matchSuggestion}  create={<CreateNewUser />}/>
                    </ReferenceInput>
                    <BooleanInput label="Es global" source="is_global" defaultValue={true}  />
                    <BooleanInput label="Es hot? Al seleccionar esto, la publicacion se vera automaticamente en el feed general" source="is_hot" defaultValue={true}  />
                    <BooleanInput label="Es visible? Al seleccionar esto la publicacion quedara publica en el perfil de la usuaria" source="is_visible"  defaultValue={true} />
                    Crea una bajada para el post, esto es muy importante para Tendencias CBC.Puedes agregas titulos,link y mas.
                    <RichTextInput source="description" validate={[required(),validateLength]} />
                    Selecciona a que categoria pertenece el post, si piensas en una mejor, creala. Para travel selecciona entre las siguientes : 
                    Hoteles , Restaurantes, Bares y Cafeterías, Panoramas Turísticos, Museos y Galerías, Playas y Costas y Actividades al Aire Libre
                    <ReferenceArrayInput source="categories" reference="categories">
                        <AutocompleteArrayInput label="categories" fullWidth={true}   optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateTag />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear categorias ',}} /> 
                    </ReferenceArrayInput>
                    Si el post pertenece a travel, puedes agregar una nota sobre la experiencia del lugar.
                    <NumberInput source="grade"  min={1} max={5} step={0.5} helperText={"Solo completar para travel"}/>
                    Si el post pertenece a travel , o es un evento, debes ingresar una ubicacion. Estos son lugares, se especifico. Se guardara automaticamente el pais y la ciudad.
                    <PlaceInput source="place" label="Place"   />
                     
                    
                     
                </FormTab>
            </TabbedForm>
        </Create>
    );
};


export default PostCreate;