import * as React from 'react';
import {
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FormTab,
    ImageField,
    ImageInput,
    Pagination,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    useGetManyReference,
    useTranslate,
    Link,
    FieldProps,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useCreateSuggestionContext,
    useCreate,

} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Card, Dialog,DialogContent, TextField as MUITextField,Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';


//import { ProductEditDetails } from './ProductEditDetails';
import UserReferenceField from '../users/UserReferenceField';
//import PostReferenceField from '../posts/PostReferenceField';
//import StarRatingField from '../reviews/StarRatingField';
import Apparel from './Apparel';
import { Post, Product } from '../types';

const PostLinkField = (props: FieldProps<Post>) => {
    const record = useRecordContext<Post>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/users/${record.id}`}>
            <TextField source="id" />
        </Link>
    );
};


const ProductTitle = () => {
    const record = useRecordContext<Product>();
    return record ? <span>Poster "{record.reference}"</span> : null;
};




const matchSuggestionName = (filter: string, choice: {name: string }) => {
    return (
        choice?.name?.toLowerCase().includes(filter.toLowerCase())
    );
    }   






const CreateBrand = () => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [value, setValue] = React.useState(filter || '');
    const [link, setLink] = React.useState('');
    const [create] = useCreate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            'brands',
            {
                data: {
                    name: value,
                    link : link,
                },                
            },
            {
                onSuccess: (data) => {
                    setValue('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label="Nueva Marca "
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                    <br />
                     <MUITextField
                        label="Link Marca "
                        value={link}
                        onChange={event => setLink(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const ProductEdit = () => (
    <Edit title={<ProductTitle />}>
        <TabbedForm>
            <FormTab
                label="resources.products.tabs.details"
                sx={{ maxWidth: '40em' }}
            >
                <Apparel />
                <TextInput label="Link" source="_link" validate={required()} />
                <ReferenceInput source="brand" reference="brands">
                    <AutocompleteInput validate={required()} optionText='name'  matchSuggestion={matchSuggestionName} create={<CreateBrand/>} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear una marca ',}} />
                </ReferenceInput>
                <TextInput source="name" fullWidth validate={required()} />
                <RichTextInput source="description" />
                <ReferenceArrayInput source="details" reference="details">
                        <AutocompleteArrayInput  validate={required()} label="Detalles" fullWidth={true}  optionText='value'  /> 
                </ReferenceArrayInput>
            </FormTab>
            <ReviewsFormTab path="recommendations">
                <ReferenceManyField
                    reference="recommendations"
                    target="product"
                    pagination={<Pagination />}
                >
                    <Datagrid
                        sx={{
                            width: '100%',
                            '& .column-comment': {
                                maxWidth: '20em',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            },
                        }}
                    >
                        <DateField source="created_at" />
                        <UserReferenceField />
                        {/*<StarRatingField />*/}
                        <PostLinkField />
                        <TextField source="status" />
                        <EditButton />
                    </Datagrid>
                </ReferenceManyField>
            </ReviewsFormTab>
        </TabbedForm>
    </Edit>
);

const req = [required()];

const ReviewsFormTab = (props: any) => {
    const record = useRecordContext();
    const { isLoading, total } = useGetManyReference(
        'recommendations',
        {
            target: 'product',
            id: record.id,
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'id', order: 'DESC' },
        },
        {
            enabled: !!record,
        }
    );
    const translate = useTranslate();
    let label = translate('resources.products.tabs.recommendations');
    if (!isLoading) {
        label += ` (${total})`;
    }
    return <FormTab label={label} {...props} />;
};

export default ProductEdit;