import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
    useGetList,
} from 'react-admin';

import { Album, Category } from '../types';

const Aside = () => {
    const { data: albums } = useGetList<Album>('albums', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'name', order: 'ASC' },
    });
    const { data: categories } = useGetList<Category>('categories', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'posts.count', order: 'ASC' },
    });

    return (
        <Card
            sx={{
                display: { xs: 'none', md: 'block' },
                order: -1,
                width: '15em',
                mr: 2,
                alignSelf: 'flex-start',
            }}
        >
            <CardContent sx={{ pt: 1 }}>
                <FilterLiveSearch />

                <SavedQueriesList />
{/* 
                <FilterList
                    //label="resources.products.filters.sales"
                    label="Álbumes"
                    //icon={<AttachMoneyIcon />}
                    icon={<LocalOfferIcon />}
                >
                    {albums &&
                    albums.map((album: any) => (
                            <FilterListItem
                                label={inflection.humanize(album.name)}
                                key={album.id}
                                value={{ album: album.id }}
                            />
                        ))}
                </FilterList> */}

                {/* <FilterList
                    //label="resources.products.filters.sales"
                    label="Categorías"
                    //icon={<AttachMoneyIcon />}
                    icon={<LocalOfferIcon />}
                >
                    {categories &&
                    categories.map((category: any) => (
                            <FilterListItem
                                label={`${inflection.humanize(category.name)} (${category.count})`}
                                key={category.id}
                                value={{ categories: category.id }}
                            />
                        ))}
                </FilterList> */}
            </CardContent>
        </Card>
    );
};

export default Aside;
