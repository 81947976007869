import * as React from 'react';
import {
    Datagrid,
    SelectField,
    Edit,
    FormTab,
    ReferenceManyField,
    required,
    TabbedForm,
    TextField,
    TextInput,
    useRecordContext,
    ReferenceInput,
    ReferenceField,
    DateTimeInput,
    FileInput,
    FileField,
    AutocompleteInput,
    Button,
    DeleteButton,
    FunctionField,
    RichTextField,
    NumberField,
    DatagridProps,
    useListContext,
    NumberInput,
    useGetOne,
    useInput,
    useUpdate,
    ReferenceArrayField,
    useGetMany,
    useCreateSuggestionContext,
    useCreate,
    useGetManyReference,
    SelectInput,
    DateField,
    SingleFieldList,
    ChipField
} from 'react-admin';
import { Benefit } from '../types';
import { RichTextInput } from 'ra-input-rich-text';
import UserSelect from './UserSelect';
import ArrowDropUp from "@mui/icons-material/ArrowDropUp"
import ArrowDropDown from "@mui/icons-material/ArrowDropDown"
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { textSpanContainsTextSpan } from 'typescript';
import { Card, Dialog, DialogContent, TextField as MUITextField, Button as MUIButton, DialogActions, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';








export const CurrentIndexField = (props: any) => {
    const record = useRecordContext(props);
    if (  (record.__index__ <= props.limite_personas && record.state== -1)  || record.state=="sent" ) {
        return record && record.__index__ !== undefined ? <span style={{ color: "green", fontWeight: "bold" }}>{record.__index__} </span> : <span></span>;
    }
    else {
        return record && record.__index__ !== undefined ? <span style={{ color: "grey" }}>{record.__index__} </span> : <span></span>;
    }

};


const BulkRowButton = (props: any) => {
    const record = useRecordContext();
    const assignations = props.data;
    const hasChange = props.hasChange;
    const sent = assignations.some((item: { state: number; }) => item.state > -1);
    console.log("enviado?" , sent)

    const [update, { isLoading, error }] = useUpdate();
    // Asignar a los que queden dentro del limit 
    const handleClick = () => {
        const elements = props.data
        const n = record.limit
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.state == -1) {
                if (i < n) {
                    update(
                        'assignations',
                        { id: element.id, data: { priority: element.__index__, state: 0 }, previousData: element }
                    )
                } else {
                    update(
                        'assignations',
                        { id: element.id, data: { priority: element.__index__ }, previousData: element }
                    )
                }

            }

        }
    }
    const handleClickUpdate = () => {
        const elements = props.data
        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            if (element.state == -1) {
                    update(
                        'assignations',
                        { id: element.id, data: { priority: element.__index__ }, previousData: element }
                    )
                }
            }
    }
    return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
           { assignations && !sent ? 
           <Button
                children={<div style={{ display: "flex", flexDirection: "row", margin: 0, alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "green", padding: 3, borderRadius: 5 }}><CheckCircleOutlineIcon />
                    <p style={{ fontSize: 10, margin: 0, padding: 0, color: "white" }}>Asignar Prioridades y enviar </p></div>}
                onClick={(e) => {e.preventDefault();handleClick()}}
            /> : 
            (hasChange ? 
            <Button
                children={<div style={{ display: "flex", flexDirection: "row", margin: 0, alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "green", padding: 3, borderRadius: 5 }}><CheckCircleOutlineIcon />
                    <p style={{ fontSize: 10, margin: 0, padding: 0, color: "white" }}>Guardar Cambios en Lista de Espera </p></div>}
                onClick={(e) => {e.preventDefault();handleClickUpdate()}}
            /> : 
            <div/> )
            }
        </div>
    );
};


export const DataGridWithIndex: React.FC<DatagridProps> = React.forwardRef((props, ref) => {
    const { children, ...rest } = props;
    interface MyObject {
        created_at: string;
        id: number;
        priority: number;
        state: number;
        updated_at: string;
        user: number;
        __index__?: number; // Añadimos __index__ como atributo opcional
    }

    const benefit = useRecordContext();
    const { data: rawData } = useListContext();
    const [myData, setMyData] = React.useState<MyObject[]>([]);
    const [hasChange, setHasChange]  = React.useState(false);
    const limite_personas = benefit.limit
    React.useEffect(() => {
        if (rawData) {
            const sortedArray = [...rawData].sort((a, b) => {
                if (a.priority === 0 && b.priority === 0) {
                    // Ambos elementos tienen prioridad 0, ordenar por fecha de creación
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    return dateA.getTime() - dateB.getTime();
                } else if (a.priority === 0) {
                    // Solo el elemento 'a' tiene prioridad 0, se coloca al final
                    return 1;
                } else if (b.priority === 0) {
                    // Solo el elemento 'b' tiene prioridad 0, se coloca al final
                    return -1;
                } else {
                    // Ambos elementos tienen prioridad distinta de 0, ordenar por prioridad ascendente
                    return a.priority - b.priority;
                }
            });

            const newArray = sortedArray.map((item, index) => ({
                ...item,
                __index__: index + 1
            }));
            setMyData(newArray)
        }

    }, [rawData]);

    type Direction = 'up' | 'down';
    const swapElements = (arr: any[], index: number, direction: Direction): any[] => {
        if (index <= 0 && direction === 'up' || index >= arr.length - 1 && direction === 'down') {
            throw new Error("Index out of bounds");
        }
        const newArray: any[] = [...arr]; // Create a copy of the array
        const temp: any = newArray[index];

        if (direction === 'up') {
            temp.__index__ = index;
            const aux = newArray[index - 1];
            if (aux.state == 0) {
                return arr
            }
            aux.__index__ = index + 1;
            newArray[index] = aux;
            newArray[index - 1] = temp;
        } else {
            temp.__index__ = index + 2;
            const aux = newArray[index + 1];
            aux.__index__ = index + 1;
            newArray[index] = aux;
            newArray[index + 1] = temp;
        }

        return newArray;
    };


    interface UpdatePriorityButtonProps {
        benefit: any; // Tipo de tu registro
        setHasChange: React.Dispatch<React.SetStateAction<boolean>>; // Tipo de setState para setHasChange
    }
    
    const UpdatePriorityButton: React.FC<UpdatePriorityButtonProps> = ({ benefit, setHasChange }) => {
        const context = useRecordContext();
        const benefitsss = benefit;
        const my_index = context.__index__;
        if (context.state == -1) {
            return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        setMyData(swapElements(myData, my_index - 1, 'up'));
                        setHasChange(true);
                    }}>
                        <ArrowDropUp />
                    </Button>
                    <Button onClick={(e) => {
                        e.stopPropagation();
                        setMyData(swapElements(myData, my_index - 1, 'down'));
                        setHasChange(true);
                    }}>
                        <ArrowDropDown />
                    </Button>
                </div>)

        }
        else {
            return (
                <div />
            )
        }
    }
    return (
        <div>
            <BulkRowButton data={myData} hasChange={hasChange} />
            <Datagrid {...rest} ref={ref} data={myData}>
                <CurrentIndexField label="Prioridad" limite_personas={limite_personas} />
                {children}
                <FunctionField label="Actualizar prioridades" render={(record: any) => <UpdatePriorityButton benefit={record} setHasChange={setHasChange} />} />
                <FunctionField label="Eliminar" render={(record: any) => <MyDeleteButton benefit={record} />} />
            </Datagrid>
        </div>
    );
});




const BenefitTitle = () => {
    const record = useRecordContext<Benefit>();
    return record ? <span>{record.title}</span> : null;
};


const MyDeleteButton = (props: any) => {
    const benefit = props.benefit;
    if (benefit.state == -1) {
        return (<DeleteButton redirect={'/benefits/' + benefit.benefit} />)
    }
    return (
        <div />
    )
};





const AssignationDetails = (record: any) => {
    const context = record.record;
    const { data: benefit, isLoading, error } = useGetOne('benefits', { id: context.benefit });
    const reciepts_fetch = useGetManyReference(
        'receipts',
        {
            target: 'assignation',
            id: record.id,
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'published_at', order: 'DESC' }
        }
    );
    const reciepts = reciepts_fetch.data
    console.log(reciepts)
    const [comment, setComment] = React.useState();
    const [grade, setGrade] = React.useState();
    



    const [accept, { isLoading: isLoading2 , error: error2 }] = useUpdate(
        'assignations',
        { id: context.id,data :  { state : 4, comment_grade:comment,admin_grade :grade }, previousData: record }
    );

    const [denied, {isLoading: isLoading3 , error: error3 }] = useUpdate(
        'assignations',
        { id: context.id, data: { state : 5,admin_comment:comment,admin_grade :grade  }, previousData: record }
    );


   
    const [timeLeft, setTimeLeft] = React.useState({ days: 0, hours: 0, minutes: 0 })

    React.useEffect(() => {
        console.log(context.sent_at)
        console.log(context.accepted_at)
        console.log(benefit.time_to_accept)
        console.log(benefit.time_to_succeed)
        const calculateValue = () => {
            if (context.state == 0) {
                const time_left = calculateTimeRemaining(context.sent_at, benefit.time_to_accept)
                setTimeLeft(time_left)
                console.log(timeLeft)
            }
            if (context.state == 1) {
                const time_left = calculateTimeRemaining(context.accepted_at, benefit.time_to_succeed)
                setTimeLeft(time_left)
                console.log(timeLeft)
            }
        };
        calculateValue();
        const interval = setInterval(() => {
            calculateValue();
        }, 60000);
        return () => {
            clearInterval(interval);
        };
    }, []);

  
    const calculateTimeRemaining = (updated_at: string, delta: string) => {
        const [deltaDays, deltaHours, deltaMinutes] = delta.split(':').map((value) => parseInt(value, 10));
        // Obtiene la fecha de actualización y el plazo límite
        const updatedAt = new Date(updated_at);
        const deadline = new Date(updatedAt);


        // Agrega el delta de días, horas y minutos al plazo límite
        deadline.setDate(deadline.getDate() + deltaDays);
        deadline.setHours(deadline.getHours() + deltaHours);
        deadline.setMinutes(deadline.getMinutes() + deltaMinutes);

        // Calcula la diferencia de tiempo entre ahora y el plazo límite
        const now = new Date();
        const timeRemaining = deadline.getTime() - now.getTime();

        // Calcula la cantidad de días, horas y minutos restantes
        const remainingDays = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const remainingHours = Math.floor(
            (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const remainingMinutes = Math.floor(
            (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );

        return {
            days: remainingDays,
            hours: remainingHours,
            minutes: remainingMinutes,
        };
    };


    //Waitlisted-Ignored - no debería mostrar nada ?
    if ([-1].includes(context.state)) {
        return (
            <div>
                Esperando la asignacion del beneficio
            </div>
        )
    }
    //Send -> Mostrar tiempo que le queda para responder 
    else if (context.state == 0) {
        return (
            <div>
                <h1>Enviado</h1>
                <div>
                    Tiempo disponible para aceptar : {timeLeft.days > 0 ? (timeLeft.days + " días") : ""} {timeLeft.hours > 0 ? (timeLeft.hours + " horas") : ""}  {timeLeft.minutes > 0 ? (timeLeft.minutes + "minutos") : ""}
                    {(timeLeft.days + timeLeft.hours + timeLeft.minutes) <= 0 ? "Tiempo Agotado" : ""}
                </div>
            </div>
        )
    }
    //Rejected
    else if (context.state == 2) {
        // TODO :RELLENAR ESTO 
        return (
            <div>
                <h1>Rechazado por usuaria</h1>
                <div>{context.user_comment}</div>
            </div>
        )
    }
    // Accepted  -> mostrar si es que existe reciept con su info, ponerle nota y comentario y boton succed o failed
    else if (context.state == 1) {
        //Ver si hay algún recibo 
        const handleCommentChange = (e: any) => {
            setComment(e.target.value); // Actualiza el estado 'comment' con el valor del campo de texto
          };

          const handleGradeChange = (e: any) => {
            setGrade(e.target.value); // Actualiza el estado 'comment' con el valor del campo de texto
          };
        
        return (
            <div>
                <h1>Aceptado</h1>
                <div>
                    {(timeLeft.days + timeLeft.hours + timeLeft.minutes) <= 0 ? "Tiempo Agotado" : 
                    <span> Tiempo disponible completar beneficio : {timeLeft.days > 0 ? (timeLeft.days + " días") : ""} {timeLeft.hours >= 0 ? (timeLeft.hours + " horas") : ""}  {timeLeft.minutes >= 0 ? (timeLeft.minutes + "minutos") : ""}
                    </span>
                    }
                </div>
                {reciepts && reciepts.length> 0 ? "Comprobantes: "  : "Comprobantes : No hay comprobantes aun" }
                {reciepts ?
                    reciepts.map((item,index) => {
                        const filepath = item.receipt_file.split('/');
                        // Retrieve the last element of the array
                        const lastElement = filepath[filepath.length - 1];
                        return (
                            <div key={index} style={{fontSize:"smaller", textDecoration:"1px underline",width:"85vw",display:"flex",margin:"10px 0px 10px 0px"}} onClick={() => window.open(item.receipt_file, "_blank", "noopener,noreferrer")}>  
                            Comprobante  {index }  : <br/>
                             {lastElement} 
                            </div>
                        );
                    })
                    :
                    "" /* Si reciepts es falsy, retorna una cadena vacía */}
                 
                <form>
                <div>Comentario Usuaria</div>
                {context.user_comment}
               

                
                <div>Comentario : </div>
                <MUITextField value={comment} onChange={handleCommentChange}  />
                <div>Nota :  </div>
                <MUITextField value={grade} onChange={handleGradeChange} />
                <div>Resolución <Button label="Aprobar"  onClick={() => accept()}/><Button label="Rechazar"   onClick={() => denied()}/></div>
                </form>
            </div>
        )
    }
    else if (context.state == 3) {
        // TODO :RELLENAR ESTO 
        return (
            <div>
                <h1>Ignorado por usuaria</h1>
                <div>
                    Usuaria no rechazo ni acepto beneficio a tiempo
                </div>
            </div>
        )
    }
    //Suceed -> mostrar comprobante, tiempo que se demoró en subirlo y  nota y comentario 
    else if (context.state == 4) {
        return (
            <div>
                <h1>Completado</h1>
                {reciepts && reciepts.length> 0 ? "Comprobantes: "  : "Comprobantes : No hay comprobantes aun" }
                {reciepts ?
                    reciepts.map((item,index) => {
                        const filepath = item.receipt_file.split('/');
                        // Retrieve the last element of the array
                        const lastElement = filepath[filepath.length - 1];
                        return (
                            <div key={index} style={{fontSize:"smaller", textDecoration:"1px underline",width:"85vw",display:"flex",margin:"10px 0px 10px 0px"}} onClick={() => window.open(item.receipt_file, "_blank", "noopener,noreferrer")}>  
                            Comprobante  {index }  : <br/>
                             {lastElement} 
                            </div>
                        );
                    })
                    :
                    "" /* Si reciepts es falsy, retorna una cadena vacía */}

                <div>Nota Usuaria : {context.user_grade}</div>
                <div>Comentario Usuaria : </div>
                {context.user_comment}
            </div>
        )
    }
    //Failed -> mostrar si es que hay comprobante, nota y comentario 
    else {
        return (
            <div>
                <h1>Fallido </h1>
                {reciepts && reciepts.length> 0 ? "Comprobantes: "  : "Comprobantes : No hay comprobantes aun" }
                {reciepts ?
                    reciepts.map((item,index) => {
                        const filepath = item.receipt_file.split('/');
                        // Retrieve the last element of the array
                        const lastElement = filepath[filepath.length - 1];
                        return (
                            <div key={index} style={{fontSize:"smaller", textDecoration:"1px underline",width:"85vw",display:"flex",margin:"10px 0px 10px 0px"}} onClick={() => window.open(item.receipt_file, "_blank", "noopener,noreferrer")}>  
                            Comprobante  {index }  : <br/>
                             {lastElement} 
                            </div>
                        );
                    })
                    :
                    "" /* Si reciepts es falsy, retorna una cadena vacía */}

                <div>Nota Usuaria : {context.user_grade}</div>
                <div>Comentario Usuaria : </div>
                {context.user_comment}
                <div>Nota Administrador : {context.admin_grade} </div>
                <div>Comentario Administrador</div>
                {context.admin_comment}
                
            </div>
        )
    }
}


const DurationInput = (props: any) => {
    const { id, field, fieldState } = useInput(props);
    const initial_date = field.value.split(":");
    const [days, setDays] = React.useState(initial_date[0] === '' ? "" : initial_date[0]);
    const [hours, setHours] = React.useState(initial_date[1] ? initial_date[1] : "");
    const [minutes, setMinutes] = React.useState(initial_date[2] ? initial_date[2] : "");

    const handleChangeDays = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedDays = parseInt(e.target.value.replace(/\D/g, ''));
        let stringDays = "";
        if (formattedDays < 10 && formattedDays > 0) {
            stringDays = '0' + formattedDays;
        } else if (formattedDays <= 0) {
            stringDays = '00';
        }
        else{
            stringDays = stringDays + formattedDays
        }
        setDays(stringDays);
    };

    const handleChangeHours = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedHours = parseInt(e.target.value.replace(/\D/g, ''));
        console.log(formattedHours)
        let stringHours = "";
        if (formattedHours > 23) {
            stringHours = '23';
        } else if (formattedHours < 10 && formattedHours > 0) {
            stringHours = '0' + formattedHours;
        } else if (formattedHours <= 0) {
            stringHours = '00';
        }
        else{
            stringHours = stringHours + formattedHours
        }
        setHours(stringHours);
    };

    const handleChangeMinutes = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formattedMinutes = parseInt(e.target.value.replace(/\D/g, ''));
        console.log(formattedMinutes)
        let stringMinutes = "";
        if (formattedMinutes > 59) {
            stringMinutes = '59';
        } else if (formattedMinutes < 10 && formattedMinutes > 0) {
            stringMinutes = '0' + formattedMinutes;
        } else if (formattedMinutes <= 0) {
            stringMinutes = '00';
        }
        else{
            stringMinutes = stringMinutes + formattedMinutes
        }
        setMinutes(stringMinutes);
    };

    React.useEffect(() => {
        field.onChange(`${days === "" ? "00" : days}:${hours === "" ? "00" : hours}:${minutes === "" ? "00" : minutes}`);
    }, [days, hours, minutes]);

    return (
        <div className='DurationTimeBox'>
            <MUITextField
                name="days"
                value={days}
                onChange={handleChangeDays}
                type="number"
                placeholder="Dias"
            />
            <MUITextField
                name="hours"
                value={hours}
                onChange={handleChangeHours}
                type="number"
                placeholder="Horas"
            />

            <MUITextField
                name="minutes"
                value={minutes}
                onChange={handleChangeMinutes}
                type="number"
                placeholder="Minutos"
            />
        </div>
    );
};

const formatDate = (inputDate: string | number | Date) => {
    const date = new Date(inputDate);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const timezoneOffset = -date.getTimezoneOffset();
    const offsetHours = String(Math.floor(Math.abs(timezoneOffset) / 60)).padStart(2, '0');
    const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0');
    const offsetSign = timezoneOffset >= 0 ? '+' : '-';

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${offsetSign}${offsetHours}:${offsetMinutes}`;

    return formattedDate;
};





const types  = [{id:"regalo", name :"Regalo"} ,{id: "descuento", name : "Descuento"} ,{id: "viaje", name : "Viaje"} ,{id: "restaurantes", name : "Restaurantes"} ,{id: "clases", name :  "Clases"}]  ;
const validateLength = (value : any) => {
    if (value.length > 1000) {
        return "Texto debe ser de 1000 caracteres  o menos ";
    }
    return undefined;
};

const BenefitEdit = () => {
    const context = useRecordContext();


    const CreateBrand = () => {
        const { filter, onCancel, onCreate } = useCreateSuggestionContext();
        const [value, setValue] = React.useState(filter || '');
        const [link, setLink] = React.useState('');
        const [create] = useCreate();

        const handleSubmit = (event: any) => {
            event.preventDefault();
            create(
                'brands',
                {
                    data: {
                        name: value,
                        link: link,
                    },
                },
                {
                    onSuccess: (data) => {
                        setValue('');
                        onCreate(data);
                    },
                }
            );
        };

        return (
            <Dialog open onClose={onCancel}>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <MUITextField
                            label="Nueva Marca "
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            autoFocus
                        />
                        <br />
                        <MUITextField
                            label="Link Marca "
                            value={link}
                            onChange={event => setLink(event.target.value)}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <MUIButton type="submit">Save</MUIButton>
                        <MUIButton onClick={onCancel}>Cancel</MUIButton>
                    </DialogActions>
                </form>
            </Dialog>
        );
    };

    const matchSuggestionName = (filter: string, choice: { name: string }) => {
        return (
            choice?.name?.toLowerCase().includes(filter.toLowerCase())
        );
    }


    return (
        <Edit title={<BenefitTitle />} redirect={false} >
            <TabbedForm>
                <FormTab
                    label="Asignar Beneficios"
                    sx={{ maxWidth: '40em' }}
                >
                    <Grid item xs={12} md={4}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div className='Title'><TextField source="title" /></div>
                            <ReferenceField source="brand" reference="brands">
                                <TextField source="name" />
                            </ReferenceField>
                            <div><RichTextField source="description" /></div>
                            <FileField source="attachment" title="Descargar Beneficio" target="_blank" />
                            <div>Tipo de Beneficio :  <TextField source='benefit_type' /></div>
                            <div>Numero maximo de invitaciones : <NumberField source="limit" label="Máximo número de invitados" /></div>
                            <div> Ultimo dia par canjear beneficio : <DateField  source="expiration" /> </div>
                        </div>

                    </Grid>

                    <h2> Usuarios ya asignados</h2>
                    <Grid item xs={12} md={8}>
                        <ReferenceManyField
                            reference="assignations"
                            target="benefit"
                        >

                            <DataGridWithIndex
                                /*sx={{
                                    width: '100%',
                                    '& .column-comment': {
                                        maxWidth: '20em',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                    },
                                }}*/
                                rowClick="expand"
                                expand={<AssignationDetails />}
                                expandSingle
                                bulkActionButtons={false}
                            >
                                <ReferenceField source='user' reference='users'>
                                    <TextField source="first_name" />
                                    {" "}
                                    <TextField source="last_name" />
                                    <TextField source="id" />
                                </ReferenceField>
                                <ReferenceField source='user' reference='users' label='Roles'>
                                <ReferenceArrayField label="Roles" reference="roles" source="roles">
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                                </ReferenceArrayField>
                                </ReferenceField>


                                <ReferenceField source='user' reference='users' label='Segmentos'>
                                <ReferenceArrayField label="Segmentos" reference="segments" source="segments">
                                <SingleFieldList>
                                    <ChipField source="name" />
                                </SingleFieldList>
                                </ReferenceArrayField>
                                </ReferenceField>

                                <NumberField source="priority" />
                                <SelectField source="state" choices={[
                                    { id: -1, name: 'Lista de Espera' },
                                    { id: 0, name: 'Enviado' },
                                    { id: 1, name: 'Aceptado' },
                                    { id: 2, name: 'Rechazado' },
                                    { id: 3, name: 'Ignorado' },
                                    { id: 4, name: 'Completado' },
                                    { id: 5, name: 'Fallido' },
                                ]} />

                            </DataGridWithIndex>

                        </ReferenceManyField>
                        <h2> Usuarios no  asignados</h2>
                        <FunctionField render={(record: any) => <UserSelect benefit={record} />} />
                    </Grid>
                </FormTab>
                <FormTab
                    label="Editar Beneficios"
                    sx={{ maxWidth: '40em' }}
                >

                Selecciona el Tipo de Benefico
                    <SelectInput
                        source="benefit_type"
                        choices={types}
                        optionText="name"
                        optionValue="id"
                        validate={required()} 
                    />
                    Selecciona  a que marca corresponde este beneficio.
                    <ReferenceInput source="brand" reference="brands">
                        <AutocompleteInput validate={required()}  fullWidth={true}optionText='name' matchSuggestion={matchSuggestionName} create={<CreateBrand />} TextFieldProps={{
                            placeholder: 'Comienza escribiendo para buscar o crear una marca ',
                        }} />
                    </ReferenceInput>
                    Utiliza un titulo descriptivo y llamativo.
                    <TextInput source="title" validate={required()}  fullWidth={true}/>
                    Describe el beneficio, las reglas y las tareas que se deben cumplir para poder completarlo.
                    <RichTextInput source="description" validate={[required(),validateLength]} />
                    Selecciona cuantos invitados pueden utilizar el beneficio.
                    <NumberInput source="limit" label="Máximo número de invitados" validate={required()}/>
                    Delimita un tiempo para que las influencers puedan aceptar un nuevo beneficio, antes de que corra la lista de espera.( Dias : Horas : Minutos)
                    <DurationInput source="time_to_accept"  validate={required()}/>
                    Delimita un tiempo para que las influencers puedan enviar sus comprobantes, antes de definir el beneficio como incompleto.( Dias : Horas : Minutos)
                    <DurationInput source="time_to_succeed" validate={required()} />
                    Selecciona el ultimo dia donde el beneficio puede ser  completado.
                    <DateTimeInput source="expiration" parse={v => formatDate(v)} label="Último día válido para el beneficio" validate={required()} />

                    Si es que existe, adjunta un archivo para que las influencers que acepten tenga mas informacion del beneficio.
                    <FileInput source="attachment">
                        <FileField source="src" title="title" />
                    </FileInput>


                </FormTab>
            </TabbedForm>
        </Edit>
    );
}

const req = [required()];


export default BenefitEdit;